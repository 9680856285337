import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Environment from 'types/Environment';

const environmentSlice = createSlice({
  name: 'environment',
  initialState: {} as Environment,
  reducers: {
    setEnvironment(state, action: PayloadAction<Environment>) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setEnvironment } = environmentSlice.actions;

export default environmentSlice.reducer;
