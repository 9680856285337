import React from 'react';
import Confirmation from '@paprika/confirmation';
import { useI18n } from '@paprika/l10n';
import { cellClassName, cellContainerClassName } from './ScriptNodeHelpers';
import './RemoveCellConfirmation.scss';

type Props = {
  cellIndex: number;
  onClose: () => void;
  onConfirm: () => void;
};

export default function RemoveCellConfirmation({ cellIndex, onClose, onConfirm }: Props) {
  const I18n = useI18n();

  const $cellContainer = document.getElementsByClassName(cellContainerClassName)[0] as HTMLElement;
  const $cell = document.getElementsByClassName(cellClassName)[cellIndex] as HTMLElement;
  const offset = $cell.clientHeight * -1;

  return (
    <Confirmation
      align="bottom"
      confirmLabel={I18n.t('script_node.cell.delete_confirmation_label')}
      defaultIsOpen
      edge="left"
      getPositioningElement={() => $cell}
      getScrollContainer={() => $cellContainer}
      heading={I18n.t('script_node.cell.delete_confirmation_heading')}
      offset={offset}
      onClose={onClose}
      onConfirm={onConfirm}
    >
      <Confirmation.Content className="remove-cell-confirmation__content" />
    </Confirmation>
  );
}
