import { NodeState } from '../types';

const mockFlow = {
  data: {
    version: '2020-02-08',
    nodes: [
      {
        id: 'node-1',
        name: 'From Jira',
        state: NodeState.Completed,
        timestamp: 1585175600,
        position: {
          x: 50,
          y: 50,
        },
      },
      {
        id: 'node-2',
        name: 'Script',
        state: NodeState.InProgress,
        timestamp: 1585175601,
        position: {
          x: 250,
          y: 120,
        },
      },
      {
        id: 'node-3',
        name: 'Summarize',
        state: NodeState.Stale,
        timestamp: 1585175602,
        position: {
          x: 450,
          y: 50,
        },
      },
      {
        id: 'node-4',
        name: 'To Results',
        timestamp: 1585175603,
        position: {
          x: 650,
          y: 120,
        },
      },
    ],
    edges: [
      {
        id: 'edge-1',
        sourceId: 'node-1',
        targetId: 'node-2',
        timestamp: 1585175603,
      },
      {
        id: 'edge-2',
        sourceId: 'node-2',
        targetId: 'node-3',
        timestamp: 1585175604,
      },
      {
        id: 'edge-3',
        sourceId: 'node-3',
        targetId: 'node-4',
        timestamp: 1585175604,
      },
    ],
  },
};

export default mockFlow;
