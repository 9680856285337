import React from 'react';
import { useI18n } from '@paprika/l10n';
import Modal from '@paprika/modal';
import Textarea from '@paprika/textarea';
import Button from '@paprika/button';
import FormElement from '@paprika/form-element';
import './Modals.scss';

type Props = {
  onCancel: () => void;
  onCommit: (commitMsg: string) => void;
};

export default function CommitModal({ onCancel, onCommit }: Props) {
  const [commitMsg, setCommitMsg] = React.useState<string>('');
  const I18n = useI18n();

  return (
    <Modal isOpen size="small" onClose={onCancel} zIndex={7}>
      <Modal.Header onClose={onCancel}>{I18n.t('commit_modal.heading')}</Modal.Header>
      <Modal.Content>
        <FormElement>
          <FormElement.Label className="commit-modal__label">{I18n.t('commit_modal.message_label')}</FormElement.Label>
          <FormElement.Content>
            {(a11yProps) => (
              <Textarea {...a11yProps} value={commitMsg} onChange={(e) => setCommitMsg(e.target.value)} />
            )}
          </FormElement.Content>
        </FormElement>
      </Modal.Content>
      <Modal.Footer>
        <Button kind="primary" onClick={() => onCommit(commitMsg)} isDisabled={commitMsg === ''}>
          {I18n.t('commit_modal.confirm_button')}
        </Button>
        <Button onClick={onCancel}>{I18n.t('commit_modal.cancel_button')}</Button>
      </Modal.Footer>
    </Modal>
  );
}
