import React from 'react';
import BubblyDefault from '@acl-services/wasabicons/lib/BubblyDefault';
import NotificationCard from '@paprika/notification-card';
import { useI18n } from '@paprika/l10n';
import { ReactComponent as BubblyArrow } from './bubbly-arrow.svg';
import './BubblyInstructions.scss';

export default function BubblyInstructions() {
  const I18n = useI18n();

  return (
    <div className="flow-bubbly">
      <BubblyArrow aria-hidden />
      <NotificationCard maxWidth="350px">
        <NotificationCard.Image>
          <BubblyDefault size="100%" />
        </NotificationCard.Image>
        <NotificationCard.Header level={2}>{I18n.t('bubbly.heading')}</NotificationCard.Header>
        <NotificationCard.Body
          dangerouslySetInnerHTML={{
            __html: I18n.t('bubbly.description', { learnMoreLink: 'https://www.google.com/' }),
          }}
        />
      </NotificationCard>
    </div>
  );
}
