import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import ScriptCell, { ScriptCellOutput } from 'types/ScriptCell';

function getCellInNode(cellId, nodeId, state) {
  const cells = state[nodeId] || [];
  const cell = cells.find((cell) => cell.id === cellId);
  if (!cell) {
    throw new Error(`could not find cell with id: ${cellId} in node with id: ${nodeId}`);
  }
  return cell;
}

const scriptCellsSlice = createSlice({
  name: 'scriptCells',
  initialState: {} as { [nodeId: string]: ScriptCell[] },
  reducers: {
    importScriptCells(state, action: PayloadAction<{ [nodeId: string]: ScriptCell[] }>) {
      return action.payload;
    },
    addScriptCell(state, action: PayloadAction<{ nodeId: string; aboveCellId: string | null; newCell: ScriptCell }>) {
      const { nodeId, aboveCellId, newCell } = action.payload;
      if (!state[nodeId]) {
        state[nodeId] = [];
      }
      const cells = state[nodeId];

      if (aboveCellId) {
        const aboveCell = getCellInNode(aboveCellId, nodeId, state);
        const aboveCellIndex = cells.indexOf(aboveCell);
        cells.splice(aboveCellIndex + 1, 0, newCell);
      } else {
        cells.push(newCell);
      }
    },
    removeScriptCell(state, action: PayloadAction<{ nodeId: string; cellId: string }>) {
      const { nodeId, cellId } = action.payload;
      const cellToRemove = getCellInNode(cellId, nodeId, state);
      const cells = state[nodeId];
      cells.splice(cells.indexOf(cellToRemove), 1);
    },
    updateScriptCell(state, action: PayloadAction<{ nodeId: string; cell: ScriptCell }>) {
      const { nodeId, cell } = action.payload;
      const cellToUpdate = getCellInNode(cell.id, nodeId, state);
      const cells = state[nodeId];
      const index = cells.indexOf(cellToUpdate);
      cells[index] = cell;
    },
    addScriptCellOutput(state, action: PayloadAction<{ nodeId: string; cellId: string; output: ScriptCellOutput }>) {
      const { nodeId, cellId, output } = action.payload;
      const cellToUpdate = getCellInNode(cellId, nodeId, state);
      if (!cellToUpdate.outputs) {
        cellToUpdate.outputs = [];
      }
      cellToUpdate.outputs.push(output);
    },
    startScriptCellExecution(state, action: PayloadAction<{ nodeId: string; cellId: string }>) {
      const { nodeId, cellId } = action.payload;
      const cellToStart = getCellInNode(cellId, nodeId, state);
      cellToStart.outputs = [];
      cellToStart.isPendingOutput = true;
    },
    endScriptCellExecution(state, action: PayloadAction<{ nodeId: string; cellId: string; executionCount: number }>) {
      const { nodeId, cellId, executionCount } = action.payload;
      const cellToEnd = getCellInNode(cellId, nodeId, state);
      cellToEnd.isPendingOutput = false;
      cellToEnd.executionCount = executionCount;
    },
    clearScriptCellOutputs(state) {
      Object.values(state).forEach((cellsInNode) => cellsInNode.forEach((cell) => (cell.outputs = [])));
    },
  },
});

export const {
  addScriptCell,
  addScriptCellOutput,
  clearScriptCellOutputs,
  endScriptCellExecution,
  importScriptCells,
  removeScriptCell,
  startScriptCellExecution,
  updateScriptCell,
} = scriptCellsSlice.actions;

export default scriptCellsSlice.reducer;
