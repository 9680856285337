import { DefaultPortModel, PortModel, PortModelAlignment, DiagramEngine } from '@projectstorm/react-diagrams';
import { AbstractModelFactory, GenerateModelEvent } from '@projectstorm/react-canvas-core';
import { FlowLinkModel } from './linkModel';

export class FlowPortModel extends DefaultPortModel {
  constructor(alignment: PortModelAlignment, maxLinks = 1) {
    super({
      type: 'flowPort',
      name: alignment,
      alignment: alignment,
      locked: alignment === PortModelAlignment.LEFT,
      maximumLinks: maxLinks,
    });
  }

  canLinkToPort(port: FlowPortModel): boolean {
    // Don't allow links to out port
    if (port.getOptions().alignment === 'right') return false;

    // Don't allow links to self
    if (this.getParent().getOptions().id === port.getParent().getOptions().id) return false;

    // Don't allow more links than maximumLinks
    if (Object.keys(port.getLinks()).length === port.getOptions().maximumLinks) return false;

    return true;
  }

  createLinkModel(): FlowLinkModel {
    return new FlowLinkModel();
  }
}

export class FlowPortFactory extends AbstractModelFactory<PortModel, DiagramEngine> {
  cb: (initialConfig?: any) => PortModel;

  constructor() {
    super('flowPort');
    this.cb = (config) => new FlowPortModel(PortModelAlignment.LEFT);
  }

  generateModel(event: GenerateModelEvent): FlowPortModel {
    return this.cb(event.initialConfig) as FlowPortModel;
  }
}
