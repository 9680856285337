import React from 'react';
import CopyInput from '@paprika/copy-input';
import './CopyButton.scss';

type Props = {
  value: string;
  buttonKind: string;
};

function CopyButton({ value, buttonKind = 'default' }: Props) {
  const getScrollContainer = () => {
    const scrollContainer = document.getElementsByClassName('variables__table-container');
    return scrollContainer && scrollContainer[0] !== undefined ? scrollContainer[0] : document.querySelector('body');
  };

  return (
    <CopyInput className="variables__copy-button" key={value} value={value} hasInputContainer={false}>
      <CopyInput.Button kind={buttonKind} canPropagate={false} />
      <CopyInput.Popover zIndex={9} getScrollContainer={getScrollContainer} />
    </CopyInput>
  );
}

export default CopyButton;
