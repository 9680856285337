import * as React from 'react';
import {
  NodeModel,
  NodeModelGenerics,
  DefaultNodeModelOptions,
  PortModelAlignment,
  DiagramEngine,
} from '@projectstorm/react-diagrams';
import { AbstractReactFactory, GenerateModelEvent, GenerateWidgetEvent } from '@projectstorm/react-canvas-core';
import { NodeType, SpecialNodeKind, DiagramNode } from '../types';
import { FlowPortModel } from './portModel';
import CanvasNode from '../CanvasNode/CanvasNode';

const MAX_SCRIPT_LINKS = 3;

function getMaxLinks(nodeKind) {
  return nodeKind === SpecialNodeKind.Script ? MAX_SCRIPT_LINKS : 1;
}

interface FlowNodeModelGenerics extends NodeModelGenerics {
  PORT: FlowPortModel;
}

interface FlowNodeModelExtras {
  nodeId: string;
  nodeType: NodeType;
  nodeKind: SpecialNodeKind | null;
  nodeIcon: string;
  nodeBackgroundColor?: string;
}

interface FlowNodeModelOptions extends DefaultNodeModelOptions {
  extras: FlowNodeModelExtras;
}

type Params = Pick<DiagramNode, 'id' | 'type' | 'kind' | 'icon' | 'backgroundColor'>;

export class FlowNodeModel extends NodeModel<FlowNodeModelGenerics> {
  constructor(diagramNode: Params) {
    const nodeObj: FlowNodeModelExtras = {
      nodeId: diagramNode.id,
      nodeType: diagramNode.type,
      nodeKind: diagramNode.kind,
      nodeIcon: diagramNode.icon,
      nodeBackgroundColor: diagramNode.backgroundColor,
    };

    const maxLinks = getMaxLinks(nodeObj.nodeKind);

    const superOptions: FlowNodeModelOptions = {
      type: `${nodeObj.nodeType}Node`,
      extras: nodeObj,
    };

    super(superOptions);

    switch (nodeObj.nodeType) {
      case NodeType.Source:
        this.addPort(new FlowPortModel(PortModelAlignment.RIGHT, maxLinks));
        break;
      case NodeType.Destination:
        this.addPort(new FlowPortModel(PortModelAlignment.LEFT, maxLinks));
        break;
      case NodeType.Transformation:
        this.addPort(new FlowPortModel(PortModelAlignment.LEFT, maxLinks));
        this.addPort(new FlowPortModel(PortModelAlignment.RIGHT, maxLinks));
        break;
      default:
        break;
    }
  }

  getOptions() {
    return this.options as FlowNodeModelOptions;
  }
}

export class FlowNodeFactory extends AbstractReactFactory<FlowNodeModel, DiagramEngine> {
  constructor(nodeType: string) {
    super(`${nodeType}Node`);
  }

  handleKeyPress = (node) => {
    node.setSelected(true);
    this.engine.repaintCanvas();
  };

  generateReactWidget(event: GenerateWidgetEvent<FlowNodeModel>): JSX.Element {
    return <CanvasNode engine={this.engine} node={event.model} onKeyPress={this.handleKeyPress} />;
  }

  // generateModel() is required but never used
  generateModel(event: GenerateModelEvent) {
    return new FlowNodeModel({
      id: '',
      type: NodeType.Transformation,
      icon: '',
      kind: null,
    });
  }
}
