// TODO: move this to package/common
enum MessageAction {
  Result = 'result',
  ServiceReady = 'serviceReady',
  Command = 'command',
  ContainerFiles = 'containerFiles',
  DownloadStatus = 'downloadStatus',
}

export default MessageAction;
