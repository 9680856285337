import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HighbondInfo from 'types/HighbondInfo';

const highbondInfoSlice = createSlice({
  name: 'highbondInfoSlice',
  initialState: {} as HighbondInfo,
  reducers: {
    updateHighbondInfo(state, action: PayloadAction<Partial<HighbondInfo>>) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { updateHighbondInfo } = highbondInfoSlice.actions;

export default highbondInfoSlice.reducer;
