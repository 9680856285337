import React from 'react';
import { useI18n } from '@paprika/l10n';
import { useGlobalErrors } from 'providers/GlobalErrorsProvider';
import { useApi } from 'providers/ApiProvider';
import Modal from '@paprika/modal';
import Button from '@paprika/button';

export default function GlobalErrorModal() {
  const I18n = useI18n();
  const api = useApi();
  const { currentError, removeCurrentError } = useGlobalErrors();

  const needsLogin = currentError?.title === I18n.t('global_error_modal.unauthorized_title');

  function handleLogin() {
    window.open(api.getLoginUrl());
    removeCurrentError();
  }

  return (
    currentError && (
      <Modal isOpen size="small" onClose={removeCurrentError} zIndex={10}>
        <Modal.Header>{currentError.title}</Modal.Header>
        <Modal.Content>{currentError.message}</Modal.Content>
        {needsLogin && (
          <Modal.Footer>
            <Button kind="primary" onClick={handleLogin}>
              {I18n.t('global_error_modal.unauthorized_confirm_button')}
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    )
  );
}
