import React from 'react';

export default function useBackgroundListeners(backgroundRef, getEngineModel) {
  const getBackgroundOffset = React.useCallback(() => {
    const BG_OFFSET = 8;
    const offsetX = getEngineModel()?.getOffsetX();
    const offsetY = getEngineModel()?.getOffsetY();
    const zoomAdjustment = getEngineModel()?.getZoomLevel()! / 100;
    const backgroundPositionX = offsetX! + BG_OFFSET * zoomAdjustment;
    const backgroundPositionY = offsetY! + BG_OFFSET * zoomAdjustment;
    return [backgroundPositionX, backgroundPositionY];
  }, [getEngineModel]);

  const handleGridOffset = React.useCallback(
    () =>
      requestAnimationFrame(() => {
        const [backgroundPositionX, backgroundPositionY] = getBackgroundOffset();
        backgroundRef.current.style.backgroundPosition = `${backgroundPositionX}px ${backgroundPositionY}px`;
      }),
    [backgroundRef, getBackgroundOffset],
  );

  const handleGridZoom = React.useCallback(
    () =>
      requestAnimationFrame(() => {
        const BG_SIZE = 16;
        const zoomAdjustment = getEngineModel()?.getZoomLevel()! / 100;
        const backgroundSize = zoomAdjustment * BG_SIZE;
        backgroundRef.current.style.backgroundSize = `${backgroundSize}px ${backgroundSize}px`;
        const [backgroundPositionX, backgroundPositionY] = getBackgroundOffset();
        backgroundRef.current.style.backgroundPosition = `${backgroundPositionX}px ${backgroundPositionY}px`;
      }),
    [getEngineModel, backgroundRef, getBackgroundOffset],
  );

  React.useEffect(
    function addBackgroundListeners() {
      const zoomOffsetListeners = {
        offsetUpdated: handleGridOffset,
        zoomUpdated: handleGridZoom,
      };

      getEngineModel()?.registerListener(zoomOffsetListeners);
      return () => {
        getEngineModel()?.getListenerHandle(zoomOffsetListeners).deregister();
      };
    },
    [getEngineModel, handleGridOffset, handleGridZoom],
  );
}
