import { NodeType, SpecialNodeKind, NodeState, DiagramNode } from '../types';
import { jiraIcon, scriptIcon, summarizeIcon, resultsIcon } from './mockIcons.js';

const mockDiagramNodes: DiagramNode[] = [
  {
    id: 'node-1',
    libraryNodeId: 'jira-id',
    name: 'From Jira',
    kind: null,
    icon: jiraIcon,
    state: NodeState.Completed,
    type: NodeType.Source,
    position: {
      x: 0,
      y: 0,
    },
  },
  {
    id: 'node-2',
    libraryNodeId: 'script-id',
    name: 'Script',
    kind: SpecialNodeKind.Script,
    icon: scriptIcon,
    state: NodeState.InProgress,
    type: NodeType.Transformation,
    position: {
      x: 240,
      y: 128,
    },
  },
  {
    id: 'node-3',
    libraryNodeId: 'summarize-id',
    name: 'Summarize',
    kind: null,
    icon: summarizeIcon,
    state: NodeState.Stale,
    type: NodeType.Transformation,
    position: {
      x: 448,
      y: 48,
    },
  },
  {
    id: 'node-4',
    libraryNodeId: 'results-id',
    name: 'To Results',
    kind: null,
    icon: resultsIcon,
    backgroundColor: '#e5f1fe',
    type: NodeType.Destination,
    position: {
      x: 640,
      y: 112,
    },
  },
];

export default mockDiagramNodes;
