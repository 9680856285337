import React from 'react';
import ChangeTracker from 'utils/ChangeTracker';

type NextStep = null | (() => void);

export default function useUnsavedModal() {
  const [isOpen, setIsOpen] = React.useState(false);
  const nextStepRef = React.useRef<NextStep>(null);

  function handleClose() {
    setIsOpen(false);
  }

  function handleGoBack(nextStep) {
    if (ChangeTracker.hasUnsavedChanges()) {
      if (typeof nextStep === 'function') nextStepRef.current = nextStep;
      setIsOpen(true);
      return;
    }

    ChangeTracker.saveChanges();
    handleClose();
    if (nextStep) nextStep();
  }

  function handleDecline() {
    ChangeTracker.saveChanges();
    handleClose();

    if (nextStepRef.current) nextStepRef.current();
    nextStepRef.current = null;
  }

  return {
    isOpen,
    onGoBack: handleGoBack,
    onClose: handleClose,
    onDecline: handleDecline,
  };
}
