import React from 'react';
import Toast from '@paprika/toast';
import { useGlobalToast } from 'providers/GlobalToastProvider';
import './GlobalToast.scss';

export default function GlobalToast() {
  const { currentToast, removeCurrentToast } = useGlobalToast();

  return (
    currentToast && (
      <Toast
        hasCloseButton
        isFixed
        canAutoClose={currentToast.canAutoClose}
        kind={currentToast.kind}
        className="toast"
        onClose={removeCurrentToast}
      >
        {currentToast.text}
      </Toast>
    )
  );
}
