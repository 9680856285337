import React from 'react';
import { useI18n } from '@paprika/l10n';
import { modifierSymbols } from './useShortcutKeys';
import './ScriptTooltipContent.scss';

function LastKey(props: { shortcutKey: string }) {
  const { shortcutKey } = props;
  const I18n = useI18n();

  return shortcutKey.length === 1 ? shortcutKey.toUpperCase() : I18n.t(`shortcut_keys.${shortcutKey}`);
}

export default function ScriptTooltipContent(props: { content: string; shortcutCombo?: string[] }): JSX.Element {
  const { content, shortcutCombo } = props;

  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      />
      {shortcutCombo && (
        <div className="script-tooltip__shortcut-key">
          {shortcutCombo.map((key) =>
            key in modifierSymbols ? `${modifierSymbols[key]} ` : <LastKey key={key} shortcutKey={key} />,
          )}
        </div>
      )}
    </>
  );
}
