import React from 'react';
import DialogActions from '@paprika/dialog-actions';
import Modal from '@paprika/modal';

type Props = {
  headingText: string;
  bodyText: string;
  confirmButtonText: string;
  declineButtonText: string;
  onCancel: () => void;
  onConfirm: () => void;
  onDecline: () => void;
};

export default function UnsavedChangesModal({
  headingText,
  bodyText,
  confirmButtonText,
  declineButtonText,
  onCancel,
  onConfirm,
  onDecline,
  ...moreProps
}: Props) {
  return (
    <Modal isOpen size="small" onClose={onCancel} zIndex={8} {...moreProps}>
      <Modal.Header onClose={onCancel}>{headingText}</Modal.Header>
      <Modal.Content>
        <div dangerouslySetInnerHTML={{ __html: bodyText }} />
      </Modal.Content>
      <Modal.Footer>
        <DialogActions
          labelConfirm={confirmButtonText}
          labelDecline={declineButtonText}
          onCancel={onCancel}
          onConfirm={onConfirm}
          onDecline={onDecline}
        />
      </Modal.Footer>
    </Modal>
  );
}
