import React from 'react';
import classNames from 'classnames';
import truncate from 'lodash.truncate';
import Spinner from '@paprika/spinner';
import { useI18n } from '@paprika/l10n';
import { PortWidget, PortModelAlignment, DiagramEngine } from '@projectstorm/react-diagrams';
import { FlowNodeModel } from '../models/nodeModel';
import { Node, NodeState } from '../types';
import { typeIcons, stateIcons } from '../NodeIcon/nodeIcons';
import NodeIcon from '../NodeIcon/NodeIcon';
import './CanvasNode.scss';

export const NodeContext = React.createContext<{ [nodeId: string]: Node }>({});

const NAME_MAXLENGTH = 28;

interface CanvasNodeProps {
  node: FlowNodeModel;
  engine: DiagramEngine;
  onKeyPress: (node: FlowNodeModel) => void;
}

export default function CanvasNode(props: CanvasNodeProps) {
  const { node: nodeModel, engine, onKeyPress } = props;
  const { selected: isSelected } = nodeModel.getOptions();
  const { nodeId, nodeType, nodeKind, nodeIcon, nodeBackgroundColor } = nodeModel.getOptions().extras;
  const I18n = useI18n();

  const nodes = React.useContext(NodeContext);
  const node = nodes[nodeId!] || {};

  const { name: nodeName, state: nodeState, isDisabled } = node;

  function handleKeyPress(e) {
    e.preventDefault();
    if (e.key === 'Enter' || e.key === ' ') {
      onKeyPress(node.model);
    }
  }

  function renderPorts() {
    const portKeys = Object.keys(nodeModel.getPorts());
    return portKeys.map((portKey) => {
      const isOutPort = portKey === PortModelAlignment.RIGHT;
      const portClass = `${isOutPort ? 'out' : 'in'}-port`;
      return (
        <PortWidget
          key={portKey}
          className={`${portClass}__container`}
          port={nodeModel.getPort(portKey)!}
          engine={engine}
        >
          <div className={portClass} />
        </PortWidget>
      );
    });
  }

  const rootClasses = classNames(
    'canvas-node__container',
    `canvas-node--${nodeState}-state`,
    `canvas-node--${nodeType}-type`,
    `canvas-node--${nodeKind}-kind`,
    { 'canvas-node--is-selected': isSelected },
    { 'canvas-node--is-disabled': isDisabled },
  );
  const displayName = truncate(nodeName, { length: NAME_MAXLENGTH });
  const TypeIcon = nodeType in typeIcons ? typeIcons[nodeType] : null;
  const StateIcon = nodeState && nodeState in stateIcons ? stateIcons[nodeState] : null;
  const isLoading = nodeState === NodeState.Loading;

  return (
    <div
      role="button"
      className={rootClasses}
      data-canvas-node-id={nodeId}
      tabIndex={0}
      onKeyPress={handleKeyPress}
      aria-label={I18n.t('node.a11y', { displayName, type: nodeType, state: nodeState })}
      aria-pressed={isSelected || false}
      aria-haspopup={isSelected}
    >
      <div className="canvas-node" data-testid="canvas-node" style={{ backgroundColor: nodeBackgroundColor }}>
        <NodeIcon icon={nodeIcon} className="canvas-node__icon" displayName={displayName} />
        {StateIcon || isLoading ? (
          <div className="canvas-node__node-state-icon">{isLoading ? <Spinner size="small" /> : <StateIcon />}</div>
        ) : null}
        {TypeIcon ? (
          <div className="canvas-node__node-type-icon">
            <TypeIcon />
          </div>
        ) : null}
        <div className="canvas-node__name">{displayName}</div>
      </div>
      {renderPorts()}
    </div>
  );
}
