import React from 'react';
import Popover from '@paprika/popover';
import L10n from '@paprika/l10n';
import { useI18n } from '@paprika/l10n';
import { stateIcons } from '../NodeIcon/nodeIcons';
import { NodeState } from '../types';
import Locales from '../locales';
import './FlowLegend.scss';

export default function FlowLegendWithL10n(props) {
  const I18n = useI18n();

  return (
    <L10n locale={I18n.locale} locales={Locales}>
      <FlowLegend {...props} />
    </L10n>
  );
}

function FlowLegend(props) {
  const I18n = useI18n();
  const InProgressIcon = stateIcons[NodeState.InProgress];
  const CompletedIcon = stateIcons[NodeState.Completed];
  const ErrorIcon = stateIcons[NodeState.Error];
  const StaleIcon = stateIcons[NodeState.Stale];

  return (
    <Popover maxWidth={300} edge="left" {...props}>
      <Popover.Trigger className="flow-legend__trigger">{I18n.t('legend.title')}</Popover.Trigger>
      <Popover.Content>
        <Popover.Card>
          <div aria-label={I18n.t('legend.title')} role="list">
            <div className="flow-legend__state" role="listitem">
              <StaleIcon className="flow-legend__state-icon flow-legend__state-stale" aria-hidden />
              <div className="flow-legend__state--text">
                <strong>{I18n.t('legend.stale')}</strong>
                <p>{I18n.t('legend.stale_description')}</p>
              </div>
            </div>
            <div className="flow-legend__state" role="listitem">
              <CompletedIcon className="flow-legend__state-icon flow-legend__state-completed" aria-hidden />
              <div className="flow-legend__state--text">
                <strong>{I18n.t('legend.success')}</strong>
                <p>{I18n.t('legend.success_description')}</p>
              </div>
            </div>
            <div className="flow-legend__state" role="listitem">
              <InProgressIcon className="flow-legend__state-icon flow-legend__state-progress" aria-hidden />
              <div className="flow-legend__state--text">
                <strong>{I18n.t('legend.running')}</strong>
                <p>{I18n.t('legend.running_description')}</p>
              </div>
            </div>
            <div className="flow-legend__state" role="listitem">
              <ErrorIcon className="flow-legend__state-icon flow-legend__state-error" aria-hidden />
              <div className="flow-legend__state--text">
                <strong>{I18n.t('legend.error')}</strong>
                <p>{I18n.t('legend.error_description')}</p>
              </div>
            </div>
          </div>
        </Popover.Card>
      </Popover.Content>
      <Popover.Tip />
    </Popover>
  );
}
