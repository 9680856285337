import React, { useState, forwardRef } from 'react';
import Button from '@paprika/button';
import Popover from '@paprika/popover';
import PlusIcon from '@acl-services/wasabicons/lib/Add';
import MinusIcon from '@acl-services/wasabicons/lib/Dash';
import ZoomToFitIcon from '@acl-services/wasabicons/lib/ZoomToFit';
import L10n from '@paprika/l10n';
import { useI18n } from '@paprika/l10n';
import { FlowDiagramHandle } from 'components/FlowDiagram/types';
import { ZOOM_PERCENTAGES } from 'components/FlowDiagram/constants';
import Locales from '../locales';

import './ZoomControls.scss';

function ZoomControlsWithL10n(_, ref) {
  const I18n = useI18n();

  return (
    <L10n locale={I18n.locale} locales={Locales}>
      <ZoomControls ref={ref} />
    </L10n>
  );
}

function ZoomControls(_, ref) {
  const [zoomPercentage, setZoomPercentage] = useState(100);
  const I18n = useI18n();

  return (
    <>
      <Popover isEager isDark>
        <Popover.Trigger>
          {(handler, a11yAttributes) => (
            <Button.Icon
              onMouseOver={handler}
              onMouseOut={handler}
              onFocus={handler}
              onBlur={handler}
              a11yText={I18n.t('zoom_controls.zoom_out')}
              className="zoom-controls__button"
              onClick={() => setZoomPercentage(ref.current.zoomOut())}
              isDisabled={zoomPercentage <= ZOOM_PERCENTAGES[0]}
              kind="minor"
              {...a11yAttributes}
            >
              <MinusIcon />
            </Button.Icon>
          )}
        </Popover.Trigger>
        <Popover.Content>
          <Popover.Card>{I18n.t('zoom_controls.zoom_out')}</Popover.Card>
        </Popover.Content>
        <Popover.Tip />
      </Popover>
      <span className="zoom-controls__percentage">{`${zoomPercentage}%`}</span>
      <Popover isEager isDark>
        <Popover.Trigger>
          {(handler, a11yAttributes) => (
            <Button.Icon
              onMouseOver={handler}
              onMouseOut={handler}
              onFocus={handler}
              onBlur={handler}
              a11yText={I18n.t('zoom_controls.zoom_in')}
              className="zoom-controls__button"
              onClick={() => setZoomPercentage(ref.current.zoomIn())}
              isDisabled={zoomPercentage >= ZOOM_PERCENTAGES[ZOOM_PERCENTAGES.length - 1]}
              kind="minor"
              {...a11yAttributes}
            >
              <PlusIcon />
            </Button.Icon>
          )}
        </Popover.Trigger>
        <Popover.Content>
          <Popover.Card>{I18n.t('zoom_controls.zoom_in')}</Popover.Card>
        </Popover.Content>
        <Popover.Tip />
      </Popover>
      <Popover isEager isDark className="zoom-controls__button--fit">
        <Popover.Trigger>
          {(handler, a11yAttributes) => (
            <Button.Icon
              onMouseOver={handler}
              onMouseOut={handler}
              onFocus={handler}
              onBlur={handler}
              a11yText={I18n.t('zoom_controls.zoom_to_fit')}
              className="zoom-controls__button"
              onClick={() => setZoomPercentage(ref.current.zoomToFit())}
              {...a11yAttributes}
            >
              <ZoomToFitIcon size={'21px'} />
            </Button.Icon>
          )}
        </Popover.Trigger>
        <Popover.Content>
          <Popover.Card>{I18n.t('zoom_controls.zoom_to_fit')}</Popover.Card>
        </Popover.Content>
        <Popover.Tip />
      </Popover>
    </>
  );
}

// @ts-ignore
// eslint-disable-next-line no-func-assign
ZoomControls = forwardRef(ZoomControls);

export default forwardRef<FlowDiagramHandle>(ZoomControlsWithL10n);
