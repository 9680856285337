import React, { useState } from 'react';
import { useI18n } from '@paprika/l10n';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'rootReducer';
import ToastKind from 'enums/ToastKind';
import SpecialNodeKind from 'enums/SpecialNodeKind';
import ScriptNodeDetails from 'components/ScriptNode/ScriptNodeDetails';
import FullscreenSpinner from 'components/FullscreenSpinner/FullscreenSpinner';
import UnsavedChangesModal from 'components/modals/UnsavedChangesModal';
import CommitModal from 'components/modals/CommitModal';
//import NodeDetails from 'components/NodeDetails/NodeDetails';
import { useApi } from 'providers/ApiProvider';
import { useGlobalErrors } from 'providers/GlobalErrorsProvider';
import { useGlobalToast } from 'providers/GlobalToastProvider';
import HighbondInfo from 'types/HighbondInfo';
import FlowNode from 'types/FlowNode';
import ChangeTracker from 'utils/ChangeTracker';
import CanvasHeader from './CanvasHeader';
import CanvasWorkArea from './CanvasWorkArea';
// import CanvasSidePanel from './CanvasSidePanel';
import Variables from '../Variables/Variables';
import flippers from 'utils/flippers';
import useUnsavedModal from 'hooks/useUnsavedModal';
import { updateVersion } from 'slices/RobotVersionSlice';

type Props = {
  flowName: string;
  robotName: string;
};

export default function Canvas({ flowName, robotName }: Props) {
  const [currentNode, setCurrentNode] = useState<FlowNode | null>(null);
  const [isVariablesOpen, setIsVariablesOpen] = useState(false);
  const {
    isOpen: isUnsavedChangesModalOpen,
    onClose: onUnsavedChangesModalClose,
    onGoBack,
    onDecline,
  } = useUnsavedModal();
  const [isCommitModalOpen, setIsCommitModalOpen] = useState(false);
  const [isCommitPending, setIsCommitPending] = useState(false);
  const [commitCount, setCommitCount] = useState(0); // TODO: remove after flippers.flowDiagram = true

  const I18n = useI18n();
  const { robotsApi } = useApi();
  const globalErrors = useGlobalErrors();
  const globalToast = useGlobalToast();
  const dispatch = useDispatch();

  const { robotId } = useParams();
  const { orgId, flowVersion }: HighbondInfo = useSelector((state: RootState) => state.highbondInfo);

  function renderNodeDetails() {
    if (currentNode) {
      if (currentNode.kind === SpecialNodeKind.Script) {
        return (
          <ScriptNodeDetails
            commitCount={commitCount}
            nodeId={currentNode.id}
            onClose={handleDetailsClose}
            onCommitButtonClick={() => setIsCommitModalOpen(true)}
            onVariablesButtonClick={() => setIsVariablesOpen(true)}
          />
        );
      }
      //return <NodeDetails nodeId={currentNode.id} onClose={handleDetailsClose}></NodeDetails>;
      return null;
    }
  }

  function renderModals() {
    return (
      <>
        {isUnsavedChangesModalOpen && (
          <UnsavedChangesModal
            headingText={I18n.t('uncommitted_changes_modal.heading')}
            bodyText={I18n.t('uncommitted_changes_modal.body')}
            confirmButtonText={I18n.t('uncommitted_changes_modal.confirm_button')}
            data-pendo-anchor="canvas__uncommitted-changes"
            declineButtonText={I18n.t('uncommitted_changes_modal.decline_button')}
            onCancel={onUnsavedChangesModalClose}
            onConfirm={handleSaveBeforeLeaving}
            onDecline={onDecline}
          />
        )}
        {isCommitModalOpen && <CommitModal onCancel={() => setIsCommitModalOpen(false)} onCommit={handleCommit} />}
      </>
    );
  }

  function handleConfigureNode(node: FlowNode) {
    setCurrentNode(node);
  }

  function handleDetailsClose() {
    setCurrentNode(null);
  }

  function handleSaveBeforeLeaving() {
    onUnsavedChangesModalClose();
    setIsCommitModalOpen(true);
  }

  async function handleCommit(commitMsg: string) {
    setIsCommitModalOpen(false);
    setIsCommitPending(true);

    const response = await robotsApi.commitFlow(orgId, robotId, flowVersion, flowName, commitMsg);

    setIsCommitPending(false);

    if (response.ok) {
      globalToast.setToast(I18n.t('commit_modal.success_notification'), ToastKind.Success, false);
      if (!flippers.flowDiagram) {
        setCommitCount((prevCount) => prevCount + 1);
      }
      dispatch(
        updateVersion({
          version: response.newAppVersion,
          comment: commitMsg,
        }),
      );
      updateUrl(response.newAppId);
    } else {
      // show global error modal if 401 so user can log in on new tab
      if (response.status === 401) {
        globalErrors.addHttpError(401);
      }
      globalToast.setToast(response.statusText || I18n.t('commit_modal.error_message'), ToastKind.Error, false);
    }
    ChangeTracker.saveChanges();
  }

  function updateUrl(newAppId) {
    const paths = window.location.pathname.split('/');
    paths[paths.length - 1] = newAppId;
    window.history.replaceState(null, '', paths.join('/'));
  }

  function handleGoBack(targetUrl) {
    onGoBack(() => {
      window.location.assign(targetUrl);
    });
  }

  return (
    <div className="canvas" role="main">
      <CanvasHeader onClickBreadcrumbsLink={handleGoBack} />
      <CanvasWorkArea onConfigureNode={handleConfigureNode} onVariablesButtonClick={() => setIsVariablesOpen(true)} />
      {renderNodeDetails()}
      {<Variables isOpen={isVariablesOpen} onClose={() => setIsVariablesOpen(false)} />}
      {renderModals()}
      {isCommitPending && <FullscreenSpinner />}
    </div>
  );
}
