import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import File from 'types/File';

const getFile = (files, fileName): File | undefined => {
  return files.find((file) => file.name === fileName);
};

const filesSlice = createSlice({
  name: 'files',
  initialState: [] as File[],
  reducers: {
    updateFiles(state, action: PayloadAction<File[]>) {
      return action.payload;
    },
    setIsDownloadPending(state, action: PayloadAction<{ fileName: string; isDownloadPending: boolean }>) {
      const { fileName, isDownloadPending } = action.payload;
      const file = getFile(state, fileName);
      if (file) {
        file.isDownloadPending = isDownloadPending;
      }
    },
  },
});

export const { updateFiles, setIsDownloadPending } = filesSlice.actions;

export default filesSlice.reducer;
