import config from './localDevConfig.json';

const invalidCredentials = 'Invalid email or password';
const tooManyFailedLogins = 'Too many failed login attempts';

export async function login() {
  const visitAccountsWithRedirect = await fetch(`/login?redirect_uri=${encodeURIComponent(config.flowBuilderUrl)}`);
  const accountsResponse = await visitAccountsWithRedirect.text();

  if (isLoginPage(accountsResponse)) {
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: buildLoginCredentials(accountsResponse),
    };

    const loginWithCredentials = await fetch('/login', options);
    const loginResponse = await loginWithCredentials.text();

    // the response status is 200 even if these errors occur, so we need to handle them ourselves
    if (loginResponse.includes(invalidCredentials)) {
      throw new Error(invalidCredentials);
    }
    if (loginResponse.includes(tooManyFailedLogins)) {
      throw new Error(tooManyFailedLogins);
    }
  }
}

function isLoginPage(response) {
  return response.match(/<form[^>]+action="\/login"/m);
}

function buildLoginCredentials(page) {
  let credentials = `user[email]=${encodeURIComponent(config.email)}&user[password]=${encodeURIComponent(
    config.password,
  )}`;
  const csrf = getCsrfParamAndToken(page);
  if (csrf) {
    credentials += `&${csrf.param}=${encodeURIComponent(csrf.token)}`;
  }
  return credentials;
}

function getCsrfParamAndToken(page) {
  const paramMatch = page.match(/<meta.*name="csrf-param".*content="(.*)".*\/>/);
  const tokenMatch = page.match(/<meta.*name="csrf-token".*content="(.*)".*\/>/);
  if (paramMatch && tokenMatch) {
    return {
      param: paramMatch[1],
      token: tokenMatch[1],
    };
  }
  return null;
}

export const isLocalDev = () => window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';

// @ts-ignore
export const isTesting = () => process.env.NODE_ENV === 'test';

export const isStorybook = () => process.env.STORYBOOK_RUNNING;
