import * as React from 'react';
import { DefaultLinkWidget } from '@projectstorm/react-diagrams';
import { PointModel } from '@projectstorm/react-diagrams-core';
import { FlowLinkModel } from '../models/linkModel';
import { FlowLabelModel } from '../models/labelModel';
import FlowLinkSegmentWidget from './FlowLinkSegment';
import ArrowHead from './ArrowHead';

import './FlowLink.scss';

declare module '@projectstorm/react-diagrams' {
  interface DefaultLinkState {
    hasFocus: boolean;
  }
}
export default class FlowLink extends DefaultLinkWidget {
  state = {
    selected: false,
    hasFocus: false,
  };

  handleFocus = () => {
    this.setState({ hasFocus: true });
  };

  handleBlur = () => {
    this.setState({ hasFocus: false });
  };

  handleKeyDown = (isActive) => {
    const linkModel = this.props.link as FlowLinkModel;

    if (!isActive) {
      this.setState({ hasFocus: true });
      linkModel.setSelected(false);
      this.props.diagramEngine.repaintCanvas();
      return;
    }

    if (linkModel.getLabels().length < 1) {
      this.setState({ hasFocus: false });
      linkModel.addLabel(new FlowLabelModel({ linkModel }));
      linkModel.setSelected(true);
      this.props.diagramEngine.repaintCanvas();
    }
  };

  generateArrow(point: PointModel, previousPoint: PointModel): JSX.Element {
    return (
      <ArrowHead
        color={this.props.link.getOptions().color}
        colorSelected={this.props.link.getOptions().selectedColor}
        key={point.getID()}
        point={point}
        previousPoint={previousPoint}
        selected={this.state.selected || this.props.link.isSelected()}
        hasFocus={this.state.hasFocus}
      />
    );
  }

  generateLink(path: string, id: string): JSX.Element {
    const ref = React.createRef<SVGPathElement>();
    this.refPaths.push(ref);

    return (
      <FlowLinkSegmentWidget
        forwardRef={ref}
        factory={this.props.diagramEngine.getFactoryForLink(this.props.link)}
        key={`link-${id}`}
        link={this.props.link as FlowLinkModel}
        path={path}
        selected={this.state.selected}
        onKeyDown={this.handleKeyDown}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
      />
    );
  }

  render() {
    const points = this.props.link.getPoints();
    const paths: JSX.Element[] = [];
    this.refPaths = [];

    for (let j = 0; j < points.length - 1; j++) {
      paths.push(this.generateLink(this.props.link.getSVGPath(), '0'));
    }

    for (let i = 1; i < points.length - 1; i++) {
      paths.push(this.generatePoint(points[i]));
    }

    if (this.props.link.getTargetPort() !== null) {
      paths.push(this.generateArrow(points[points.length - 1], points[points.length - 2]));
    } else {
      paths.push(this.generatePoint(points[points.length - 1]));
    }

    return <g data-canvas-link-id={this.props.link.getOptions().extras.linkId}>{paths}</g>;
  }
}
