import { useEffect, useRef } from 'react';

export default function useVariablesFocus(variables) {
  const prevVariablesLength = useRef(variables.length);
  const removedVariableIndex = useRef(-1);
  const lastVariableInput = useRef<HTMLInputElement>();
  const inputToFocusAfterVariableRemoval = useRef<HTMLInputElement>();

  useEffect(() => {
    // only 1 variable
    if (lastVariableInput.current && variables.length === 1) {
      lastVariableInput.current.focus();
      // removed a variable
    } else if (inputToFocusAfterVariableRemoval.current && variables.length < prevVariablesLength.current) {
      inputToFocusAfterVariableRemoval.current.focus();
      // added a variable
    } else if (lastVariableInput.current && variables.length > prevVariablesLength.current) {
      lastVariableInput.current.focus();
    }
    prevVariablesLength.current = variables.length;
  }, [variables.length]);

  function getInputRef(index) {
    if (index === removedVariableIndex.current) {
      return inputToFocusAfterVariableRemoval;
    }
    if (index === variables.length - 1) {
      return lastVariableInput;
    }
    return null;
  }

  function handleFocusOnRemoveVariable(index) {
    if (index === variables.length - 1) {
      removedVariableIndex.current = index - 1;
    } else {
      removedVariableIndex.current = index;
    }
  }

  return { getInputRef, handleFocusOnRemoveVariable };
}
