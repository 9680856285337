import { isLocalDev } from 'localDev/localDevHelpers';
import BaseApiService from './BaseApiService';
import RobotApiService from './RobotApiService';

const ROBOT_API_HEADERS = {
  'Content-Type': 'application/vnd.api+json',
  Accept: 'application/vnd.acl.v1+json',
};

export default class ApiService extends BaseApiService {
  robotsApi: RobotApiService;

  constructor() {
    super();
    this.robotsApi = new RobotApiService();
  }

  getLoginUrl() {
    return `${this.ACCOUNTS_URL}/login`;
  }

  async fetchWithTokenRefresh(path: string, options: any = {}): Promise<Response> {
    let opts = { credentials: 'include', ...options };
    return await this.jwtClient.fetchWithTokenRefresh(`${this.API_PROXY}${path}`, opts);
  }

  async getGlobalNav(orgId) {
    // in local dev, leave out the org so that someone logged in as the Robots Dev user can change orgs without affecting development
    const orgParam = isLocalDev() ? '' : `?org_id=${orgId}`;
    const navResponse = await this.fetchWithTokenRefresh(`/accounts/api/global_nav_bar${orgParam}`);
    if (navResponse.ok) {
      const navJson = await navResponse.json();
      return navJson.data;
    }
    return Promise.reject(navResponse.status);
  }

  async getAnalytics(orgId, appId): Promise<any> {
    const analyticsResponse = await this.fetchWithTokenRefresh(
      `/v1/orgs/${orgId}/robots/robot_apps/${appId}/analytics`,
      {
        headers: ROBOT_API_HEADERS,
      },
    );
    if (analyticsResponse.ok) {
      const analyticsJson = await analyticsResponse.json();
      const analytics = this.deserialize(analyticsJson);
      return analytics;
    }
    return Promise.reject(analyticsResponse.status);
  }

  async getEncryptionKey(orgId): Promise<any> {
    const keyResponse = await this.fetchWithTokenRefresh(`/v1/orgs/${orgId}/flow_key`, {
      headers: ROBOT_API_HEADERS,
    });
    if (keyResponse.ok) {
      const keyJson = await keyResponse.json();
      const key = (this.deserialize(keyJson) as any).publicKey;
      return key;
    }
    return Promise.reject(keyResponse.status);
  }

  async tryTokenRefresh(): Promise<Response> {
    let hostname = window.location.hostname;
    let subdomain = hostname === 'localhost' ? hostname : hostname.split('.').reverse()[3];
    const region = this.REGION;
    const tokenRefreshUrl = `${this.ACCOUNTS_URL}/api/token/refresh`;

    let refreshSubdomainPromise = isLocalDev()
      ? Promise.resolve({ ok: true } as Response)
      : this.putWithCredentials(`${tokenRefreshUrl}?org_subdomain=${subdomain}&region=${region}`);

    return await refreshSubdomainPromise;
  }

  private async putWithCredentials(url: string): Promise<Response> {
    let response = await fetch(url, {
      method: 'PUT',
      credentials: 'include',
    });
    return response;
  }
}
