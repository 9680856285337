import React from 'react';
import classNames from 'classnames';
import Toast from '@paprika/toast';
import ProgressBar from '@paprika/progress-bar';
import { useI18n } from '@paprika/l10n';
import useFakeLoad from './useFakeLoad';
import './ScriptToast.scss';

type Props = {
  isComplete: boolean;
};

const RENDER_DELAY = 20;
const HOVER_DELAY = 150;
const CLOSE_DELAY = 6000;

export default function ScriptToast({ isComplete = false }: Props) {
  const I18n = useI18n();
  const value = useFakeLoad(isComplete);
  const [isVisible, setVisible] = React.useState(true);
  const [isCloneVisible, setCloneVisible] = React.useState(false);
  const [toastWidth, setToastWidth] = React.useState<string | null>(null);
  const toastRef = React.useRef();
  const closeTimer = React.useRef<any>(null);
  const measureTimer = React.useRef<any>(null);
  const hoverTimer = React.useRef<any>(null);

  const contentTitle = isComplete
    ? I18n.t('script_node.loading_toast.complete.title')
    : I18n.t('script_node.loading_toast.pending.title');

  const contentBody = isComplete
    ? I18n.t('script_node.loading_toast.complete.body')
    : I18n.t('script_node.loading_toast.pending.body');

  const toastKind = isComplete ? Toast.types.kind.SUCCESS : Toast.types.kind.INFO;

  const toastProps = {
    hasCloseButton: false,
    kind: toastKind,
  };

  function renderContent() {
    return (
      <>
        <div className="script-toast__content-title">{contentTitle}</div>
        <div className="script-toast__content-body">{contentBody}</div>
      </>
    );
  }

  function measureWidth() {
    const $toast = document.querySelector('.script-toast--main');
    const measuredWidth = $toast ? `${$toast.getBoundingClientRect().width}px` : '0';
    if (measuredWidth !== toastWidth) setToastWidth(measuredWidth);
  }

  React.useLayoutEffect(
    () => {
      measureTimer.current = setTimeout(() => {
        measureWidth();
      }, RENDER_DELAY);

      return () => {
        clearTimeout(measureTimer.current);
      };
    },
    [toastRef.current], // eslint-disable-line react-hooks/exhaustive-deps
  );

  React.useEffect(() => {
    if (isComplete) {
      clearTimeout(closeTimer.current);
      closeTimer.current = setTimeout(() => {
        setVisible(false);
      }, CLOSE_DELAY);

      return () => {
        clearTimeout(closeTimer.current);
      };
    }
  }, [isComplete]);

  React.useEffect(() => {
    return () => {
      clearTimeout(measureTimer.current);
      clearTimeout(hoverTimer.current);
      clearTimeout(closeTimer.current);
    };
  }, []);

  const handleMouseOver = () => {
    measureWidth();
    clearTimeout(hoverTimer.current);
    hoverTimer.current = setTimeout(() => {
      setCloneVisible(true);
    }, HOVER_DELAY);
  };

  const handleMouseOut = () => {
    clearTimeout(hoverTimer.current);
    setCloneVisible(false);
  };

  const rootClasses = classNames('script-toast__container', {
    'script-toast__container--collapse': !isVisible,
  });

  return (
    <div className={rootClasses}>
      <div className="script-toast__progress-container" style={{ width: toastWidth || '0' }}>
        {isVisible ? (
          <div className="script-toast__progress-trigger" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} />
        ) : null}
        <ProgressBar className="script-toast__progress-bar" completed={isComplete ? 100 : value} isCompact />
      </div>

      {isVisible && isCloneVisible ? (
        <Toast
          aria-hidden
          className="script-toast script-toast--clone"
          renderDelay={0}
          style={{ width: toastWidth }}
          {...toastProps}
        >
          {renderContent()}
        </Toast>
      ) : null}

      <Toast className="script-toast script-toast--main" ref={toastRef} renderDelay={RENDER_DELAY} {...toastProps}>
        {renderContent()}
      </Toast>
    </div>
  );
}
