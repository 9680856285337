import store from 'store';
import Edge from 'types/Edge';
import FlowNode from 'types/FlowNode';
import ScriptCell from 'types/ScriptCell';
import Variable from 'types/Variable';

type StateSnapshot = {
  edges: Edge[];
  nodes: FlowNode[];
  scriptCells: { [nodeId: string]: ScriptCell[] };
  variables: Variable[];
};

class ChangeTracker {
  private snapshot: StateSnapshot = {} as StateSnapshot;

  constructor() {
    this.takeSnapshot();
  }

  private takeSnapshot() {
    const state = store.getState();
    this.snapshot.edges = state.edges.present;
    this.snapshot.nodes = state.flowNodes.present;
    this.snapshot.scriptCells = state.scriptCells.present;
    this.snapshot.variables = state.variables.present;
  }

  saveChanges() {
    this.takeSnapshot();
  }

  hasUnsavedChanges() {
    const state = store.getState();
    return (
      this.snapshot.edges !== state.edges.present ||
      this.snapshot.nodes !== state.flowNodes.present ||
      this.snapshot.scriptCells !== state.scriptCells.present ||
      this.snapshot.variables !== state.variables.present
    );
  }
}

export default new ChangeTracker();
