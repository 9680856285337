import * as React from 'react';
import { DefaultLinkModel } from '@projectstorm/react-diagrams';

interface Props {
  model: DefaultLinkModel;
  path: string;
  selected: boolean;
}

function FlowLinkPath(props: Props, ref) {
  const { model, path, selected, ...moreProps } = props;

  return (
    <path
      ref={ref}
      stroke={selected ? model.getOptions().selectedColor : model.getOptions().color}
      strokeWidth={model.getOptions().width}
      d={path}
      {...moreProps}
    />
  );
}

export default React.forwardRef<React.ReactElement, Props>(FlowLinkPath);
