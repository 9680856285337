import React from 'react';
import { useI18n } from '@paprika/l10n';
import GlobalError from 'types/GlobalError';

type GlobalErrorsProviderValue = {
  addCustomError: (error: GlobalError) => void;
  addGenericError: () => void;
  addHttpError: (statusCode: number) => void;
  currentError: GlobalError | null;
  removeCurrentError: () => void;
};

export const GlobalErrorsContext = React.createContext<GlobalErrorsProviderValue | undefined>(undefined);

export function useGlobalErrors() {
  const context = React.useContext(GlobalErrorsContext);
  if (context === undefined) {
    throw new Error('useGlobalError must be used within a GlobalErrorProvider');
  }
  return context;
}

export default function GlobalErrorsProvider({ children }: { children: React.ReactNode }) {
  const I18n = useI18n();

  const [errors, setErrors] = React.useState<GlobalError[]>([]);

  function addCustomError(error: GlobalError) {
    setErrors((errors) => [...errors, error]);
  }

  function addHttpError(statusCode) {
    let title;
    let message;
    switch (statusCode) {
      case 401:
        title = I18n.t('global_error_modal.unauthorized_title');
        message = I18n.t('global_error_modal.unauthorized_message');
        break;
      case 429:
        title = I18n.t('global_error_modal.too_many_requests_title');
        message = I18n.t('global_error_modal.too_many_requests_message');
        break;
      default:
        addGenericError();
    }
    if (title && message) {
      addCustomError({ title, message });
    }
  }

  function addGenericError() {
    const title = I18n.t('global_error_modal.generic_title');
    const message = I18n.t('global_error_modal.generic_message');
    setErrors((errors) => [...errors, { title, message }]);
  }

  function removeCurrentError() {
    setErrors((errors) => {
      const newErrors = [...errors];
      newErrors.shift();
      return newErrors;
    });
  }

  const value: GlobalErrorsProviderValue = {
    addCustomError,
    addHttpError,
    addGenericError,
    currentError: errors.length ? errors[0] : null,
    removeCurrentError,
  };

  return <GlobalErrorsContext.Provider value={value}>{children}</GlobalErrorsContext.Provider>;
}
