import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FlowNode from 'types/FlowNode';

type UpdateNodeConfigPayload = Pick<FlowNode, 'id' | 'name' | 'config'>;
type MoveNodePayload = Pick<FlowNode, 'id' | 'position'>;

const flowNodesSlice = createSlice({
  name: 'flowNodes',
  initialState: [] as FlowNode[],
  reducers: {
    importFlowNodes(state, action: PayloadAction<FlowNode[]>) {
      return action.payload;
    },
    addFlowNode(state, action: PayloadAction<FlowNode>) {
      state.push({ ...action.payload, timestamp: getTimestamp() });
    },
    moveFlowNode(state, action: PayloadAction<MoveNodePayload>) {
      const { id, position } = action.payload;
      let nodeToMove = state.find((node) => node.id === id);
      if (nodeToMove) {
        nodeToMove.position = position;
      }
    },
    removeFlowNode(state, action: PayloadAction<string>) {
      const nodeToRemove = state.find((node) => node.id === action.payload);
      if (nodeToRemove) {
        state.splice(state.indexOf(nodeToRemove), 1);
      }
    },
    updateFlowNodeConfig(state, action: PayloadAction<UpdateNodeConfigPayload>) {
      const { id, name, config } = action.payload;
      const nodeToUpdate = state.find((node) => node.id === id);
      if (nodeToUpdate) {
        nodeToUpdate.name = name;
        nodeToUpdate.config = config;
        nodeToUpdate.timestamp = getTimestamp();
      }
    },
  },
});

function getTimestamp() {
  return Math.floor(Date.now() / 1000);
}

export const {
  addFlowNode,
  importFlowNodes,
  moveFlowNode,
  removeFlowNode,
  updateFlowNodeConfig,
} = flowNodesSlice.actions;

export default flowNodesSlice.reducer;
