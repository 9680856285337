import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActionCreators } from 'redux-undo';
import { v4 as uuidv4 } from 'uuid';
import { RootState } from 'rootReducer';

import Takeover from '@paprika/takeover';
import Heading from '@paprika/heading';
import Button from '@paprika/button';
import stylers from '@paprika/stylers';
import tokens from '@paprika/tokens';
import { useI18n } from '@paprika/l10n';
import VariablesIcon from '@acl-services/wasabicons/lib/Variable';
import AddIcon from '@acl-services/wasabicons/lib/Add';

import Toast from 'types/Toast';
import Variable from 'types/Variable';
import VariableErrors from 'types/VariableErrors';
import User from 'types/User';
import RobotCategory from 'enums/RobotCategory';
import VariableType from 'enums/VariableType';
import VariableProperty from 'enums/VariableProperty';
import ToastKind from 'enums/ToastKind';

import UnsavedChangesModal from 'components/modals/UnsavedChangesModal';
import { useWebSocket } from 'providers/WebSocketProvider';
import { useGlobalToast } from 'providers/GlobalToastProvider';
import { clearScriptCellOutputs } from 'slices/ScriptCellsSlice';
import ChangeTracker from 'utils/ChangeTracker';

import { addVariable, removeVariable, updateVariable } from '../../slices/VariablesSlice';
import VariablesTable, { APPLY_CHANGE_DELAY } from './VariablesTable';
import VariablesValidator from './VariablesValidator';
import { cleanupVariable } from './VariableModifiers';

import './Variables.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export default function Variables({ isOpen, onClose }: Props) {
  const I18n = useI18n();
  const dispatch = useDispatch();
  const webSocket = useWebSocket();
  const globalToast = useGlobalToast();
  const { robot } = useSelector((state: RootState) => state.robot);
  const variablesHistory = useSelector((state: RootState) => state.variables);
  const systemUsers: User[] = useSelector((state: RootState) => state.users.systemUsers);
  const variables = variablesHistory.present;
  const variablesRef = useRef(variables);

  const [savedStateIndex, setSavedStateIndex] = useState<number>(variablesHistory.index || 0);
  const [isUnsavedChangesModalOpen, setIsUnsavedChangesModalOpen] = useState(false);
  const [saveErrorToast, setSaveErrorToast] = useState<Toast | null>(null);
  const [singleErrors, setSingleErrors] = useState<VariableErrors>({});
  const [duplicateErrors, setDuplicateErrors] = useState<VariableErrors>({});
  const validator = useRef(new VariablesValidator(I18n.t)).current;
  const stateIndexRef = useRef(variablesHistory.index);

  useEffect(() => {
    variablesRef.current = variables;
  }, [variables]);

  useEffect(() => {
    stateIndexRef.current = variablesHistory.index;
  }, [variablesHistory.index]);

  useEffect(() => {
    setSavedStateIndex(variablesHistory.index || 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    setDuplicateErrors(validator.getDuplicateErrors(variables));
  }, [variables, validator]);

  const handleAddVariable = React.useCallback(() => {
    const newVariable: Variable = {
      id: uuidv4(),
      name: '',
      isReadOnly: false,
      type: VariableType.Character,
      value: '',
      isTaskInput: false,
      isTaskInputValueOptional: false,
    };
    dispatch(addVariable(newVariable));
  }, [dispatch]);

  useEffect(() => {
    if (variables.length === 0) {
      const shouldResetChanges = !ChangeTracker.hasUnsavedChanges();
      handleAddVariable();
      if (shouldResetChanges) {
        dispatch(ActionCreators.clearHistory());
        ChangeTracker.saveChanges();
      }
    }
  }, [dispatch, variables.length, handleAddVariable]);

  function removeToastAndClose() {
    if (globalToast.currentToast && globalToast.currentToast === saveErrorToast) {
      globalToast.removeCurrentToast();
      setSaveErrorToast(null);
    }
    onClose();
  }

  function getSingleErrors() {
    const isOnlyOneEmptyVariable =
      variablesRef.current.length === 1 && validator.isVariableEmpty(variablesRef.current[0]);
    return isOnlyOneEmptyVariable ? ({} as VariableErrors) : validator.getSingleErrors(variablesRef.current);
  }

  function handleClose() {
    if (webSocket.isExecuting) {
      handleDiscardAndClose();
      return;
    }
    // Wait for delayed onChange handlers to complete
    setTimeout(() => {
      if (savedStateIndex !== stateIndexRef.current) {
        setIsUnsavedChangesModalOpen(true);
      } else {
        const singleErrors = getSingleErrors();
        setSingleErrors(singleErrors);
        removeToastAndClose();
      }
    }, APPLY_CHANGE_DELAY);
  }

  function handleValidateVariable(variable: Variable, property: VariableProperty) {
    const singleError = validator.getSingleErrorForProperty(variable, property);
    setSingleErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      if (!newErrors[variable.id]) {
        newErrors[variable.id] = {};
      }
      newErrors[variable.id][property] = singleError;
      return newErrors;
    });
  }

  function handleRemoveVariable(variableId: string) {
    if (singleErrors[variableId]) {
      setSingleErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[variableId];
        return newErrors;
      });
    }
    dispatch(removeVariable(variableId));
  }

  function handleVariableChange(variable: Variable, property: VariableProperty, value) {
    const taskInputOptionProperties = [VariableProperty.IsTaskInput, VariableProperty.IsTaskInputValueOptional];
    setTimeout(() => {
      const filteredVariable = variablesRef.current.filter((v) => {
        return v.id === variable.id;
      });
      const isValueChanged = variable[property] !== value;

      let variableToUpdate = { ...variable };
      if (taskInputOptionProperties.includes(property)) {
        variableToUpdate = {
          ...filteredVariable[0],
          [property]: value,
        };
      } else {
        (variableToUpdate[property] as any) = value;
        variableToUpdate = cleanupVariable(variable, variableToUpdate);
      }
      handleValidateVariable(variableToUpdate, property);
      if (isValueChanged) dispatch(updateVariable(variableToUpdate));
    }, APPLY_CHANGE_DELAY);
  }

  function handleToggleReadOnlyView(variableID: string) {
    // Wait for delayed onChange handlers to complete
    setTimeout(() => {
      const filteredVariable = variablesRef.current.filter((v) => {
        return v.id === variableID;
      });
      const singleError = validator.getSingleErrors([filteredVariable[0]]);
      const duplicateErrors = validator.getDuplicateErrors(variablesRef.current);
      const duplicateError = { [variableID]: duplicateErrors[variableID] };
      const inputErrorCount = validator.getTotalErrorCount(singleError, duplicateError);

      if (inputErrorCount > 0) {
        setSingleErrors((prevErrors) => {
          const newErrors = { ...prevErrors };
          if (!newErrors[variableID]) {
            newErrors[variableID] = {};
          }
          newErrors[variableID] = singleError[variableID];
          return newErrors;
        });
        setDuplicateErrors((prevErrors) => {
          const newErrors = { ...prevErrors };
          for (const v in duplicateError) {
            if (!newErrors[v]) newErrors[v] = duplicateError[v];
          }
          return newErrors;
        });
        return;
      }
      dispatch(
        updateVariable({
          ...filteredVariable[0],
          [VariableProperty.IsReadOnly]: !filteredVariable[0].isReadOnly,
        }),
      );
    }, APPLY_CHANGE_DELAY);
  }

  function handleSaveAndClose() {
    // Wait for delayed onChange handlers to complete
    setTimeout(() => {
      const singleErrors = getSingleErrors();
      setSingleErrors(singleErrors);
      const duplicateErrors = validator.getDuplicateErrors(variablesRef.current);
      const inputErrorCount = validator.getTotalErrorCount(singleErrors, duplicateErrors);

      if (inputErrorCount > 0) {
        const toast = globalToast.setToast(
          I18n.t('variables.save_error_notification', { count: inputErrorCount }),
          ToastKind.Error,
          false,
        );
        setSaveErrorToast(toast);
        setDuplicateErrors(duplicateErrors);
      } else {
        setSavedStateIndex(variablesHistory.index || 0);
        dispatch(clearScriptCellOutputs());
        // if websocket is not connected yet, there are no old variables in the kernel
        // so it's ok that we don't send the reset message
        if (webSocket.isConnected) {
          webSocket.sendResetMessage();
        }
        removeToastAndClose();
      }
      setIsUnsavedChangesModalOpen(false);
    }, APPLY_CHANGE_DELAY);
  }

  function handleDiscardAndClose() {
    setDuplicateErrors({});
    setSingleErrors({});
    setIsUnsavedChangesModalOpen(false);
    removeToastAndClose();
    dispatch({ type: 'variablesJumpToPast', index: savedStateIndex });
  }

  return (
    <>
      <Takeover
        a11yText={I18n.t('variables.takeover_header')}
        className="variables-takeover"
        isOpen={isOpen}
        zIndex={8}
      >
        <div className="takeover__header">
          <VariablesIcon size={stylers.spacer(4)} color={tokens.textColor.icon} />
          <Heading level={2} displayLevel={3} isLight>
            {I18n.t('variables.takeover_header')}
          </Heading>
          <div className="takeover__close-btn">
            <Button.Close
              data-testid="takeover-close-btn"
              data-pendo-anchor="variables__close-takeover"
              onClick={handleClose}
            />
          </div>
        </div>
        <div className="takeover__content variables">
          <div className="variables__table-container">
            <VariablesTable
              duplicateErrors={duplicateErrors}
              onRemoveVariable={handleRemoveVariable}
              onToggleReadOnlyView={handleToggleReadOnlyView}
              onVariableChange={handleVariableChange}
              singleErrors={singleErrors}
              isSystemUserEnabled={robot?.category === RobotCategory.Workflow}
              systemUsers={systemUsers}
              variables={variables}
            />
          </div>
          <footer className="variables__footer">
            <Button
              className="variables__footer-add-button"
              data-pendo-anchor="variables__add-variable"
              icon={<AddIcon />}
              kind="minor"
              onClick={handleAddVariable}
            >
              {I18n.t('variables.add_variable_button')}
            </Button>
            <div className="variables__save-wrapper">
              <span className="variables__save-warning">
                {webSocket.isExecuting ? I18n.t('variables.save_disabled_warning') : I18n.t('variables.save_warning')}
              </span>
              <Button
                className="variables__save-and-close-button"
                data-pendo-anchor="variables__save"
                isDisabled={webSocket.isExecuting}
                kind="primary"
                onClick={handleSaveAndClose}
              >
                {I18n.t('variables.save_button')}
              </Button>
              <Button
                className="variables__footer-cancel-button"
                data-pendo-anchor="variables__cancel"
                kind="minor"
                onClick={handleClose}
              >
                {I18n.t('variables.cancel_button')}
              </Button>
            </div>
          </footer>
        </div>
      </Takeover>
      {isUnsavedChangesModalOpen && (
        <UnsavedChangesModal
          bodyText={I18n.t('variables.unsaved_changes_modal.body')}
          confirmButtonText={I18n.t('variables.unsaved_changes_modal.confirm_button')}
          data-pendo-anchor="variables__unsaved-changes"
          declineButtonText={I18n.t('variables.unsaved_changes_modal.decline_button')}
          headingText={I18n.t('variables.unsaved_changes_modal.heading')}
          onCancel={() => setIsUnsavedChangesModalOpen(false)}
          onConfirm={handleSaveAndClose}
          onDecline={handleDiscardAndClose}
        />
      )}
    </>
  );
}
