import VariableType from 'enums/VariableType';
import Variable from 'types/Variable';

export const cleanupVariable = (currentVariable: Variable, newVariable: Variable): Variable => {
  let cleanedVariable = { ...newVariable };

  const variableTypeChanged = currentVariable && hasTypeChanged(currentVariable, newVariable);
  const isSytemUserVariable = isSystemUserType(newVariable);
  const isPasswordVariable = isPasswordType(newVariable);

  // remove value when variable type change
  if (variableTypeChanged) {
    cleanedVariable = removeValue(cleanedVariable);
  }

  // password variables are automatically isTaskInput
  if (variableTypeChanged && isPasswordVariable) {
    cleanedVariable = setIsTaskInput(cleanedVariable, true);
  }

  // system user variable cannot be task input
  if (variableTypeChanged && isSytemUserVariable) {
    cleanedVariable = setIsTaskInput(cleanedVariable, false);
    cleanedVariable = removeProperty(cleanedVariable, 'isTaskInputValueOptional');
    cleanedVariable = removeProperty(cleanedVariable, 'taskInputLabel');
    cleanedVariable = removeProperty(cleanedVariable, 'taskInputDescription');
  }

  return cleanedVariable;
};

function hasTypeChanged(currentVariable: Variable, newVariable: Variable) {
  return currentVariable.type !== newVariable.type;
}

function removeValue(variable: Variable) {
  return {
    ...variable,
    value: '',
  };
}

function isSystemUserType(variable: Variable) {
  return variable.type === VariableType.SystemUser;
}

function isPasswordType(variable: Variable) {
  return variable.type === VariableType.Password;
}

function setIsTaskInput(variable: Variable, isTaskInput: boolean) {
  return {
    ...variable,
    isTaskInput,
  };
}

function removeProperty(variable, key) {
  const variableToUpdate = { ...variable };
  delete variableToUpdate[key];
  return variableToUpdate;
}
