import React, { useEffect } from 'react';
import { useI18n } from '@paprika/l10n';
import { useGlobalErrors } from 'providers/GlobalErrorsProvider';
import GlobalErrorModal from 'components/modals/GlobalErrorModal';
import GlobalError from 'types/GlobalError';

export default function Page404() {
  const I18n = useI18n();
  const { addCustomError } = useGlobalErrors();

  useEffect(() => {
    const title = I18n.t('global_error_modal.page_not_found_title');
    const message = I18n.t('global_error_modal.page_not_found_message');
    const error: GlobalError = { title, message };
    addCustomError(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <GlobalErrorModal />;
}
