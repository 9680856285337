import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Edge from 'types/Edge';

const edgesSlice = createSlice({
  name: 'edges',
  initialState: [] as Edge[],
  reducers: {
    importEdges(state, action: PayloadAction<Edge[]>) {
      return action.payload;
    },
    addEdge(state, action: PayloadAction<Edge>) {
      state.push(action.payload);
    },
    removeEdge(state, action: PayloadAction<string>) {
      const { payload: edgeId } = action;
      const edgeToRemove = state.find((edge) => edge.id === edgeId);
      if (edgeToRemove) {
        state.splice(state.indexOf(edgeToRemove), 1);
      }
    },
    removeEdgeForNode(state, action: PayloadAction<string>) {
      const { payload: nodeId } = action;
      const edgesToRemove = state.filter((edge) => edge.sourceId === nodeId || edge.targetId === nodeId);
      if (edgesToRemove) {
        edgesToRemove.forEach((edgeToRemove) => {
          state.splice(state.indexOf(edgeToRemove), 1);
        });
      }
    },
  },
});

export const { addEdge, importEdges, removeEdge, removeEdgeForNode } = edgesSlice.actions;

export default edgesSlice.reducer;
