import React from 'react';
import { useLocalStorage, writeStorage } from '@rehooks/local-storage';
import Heading from '@paprika/heading';
import Tabs from '@paprika/tabs';
import Button from '@paprika/button';
import SourceIcon from '@acl-services/wasabicons/lib/ImportCloud';
import TransformationIcon from '@acl-services/wasabicons/lib/EditAlt';
import DestinationIcon from '@acl-services/wasabicons/lib/Export2';
import { LibraryNode, NodeType } from '../types';
import NodePaletteDragHint from './NodePaletteDragHint';
import NodePaletteItem from './NodePaletteItem';
import { useI18n } from '@paprika/l10n';
import L10n from '@paprika/l10n';
import Locales from '../locales';
import './NodePalette.scss';

const infoBoxStorageKey = 'showNodePaletteInfoBox';

interface Props {
  nodes: LibraryNode[];
}

export default function NodePalleteWithL10n(props) {
  const I18n = useI18n();

  return (
    <L10n locale={I18n.locale} locales={Locales}>
      <NodePalette {...props} />
    </L10n>
  );
}

function NodePalette({ nodes }: Props) {
  const [isInfoBoxOpen] = useLocalStorage<boolean>(infoBoxStorageKey, true);
  const I18n = useI18n();

  const infoBox = (
    <div className="node-palette-info-box" data-testid="node-palette-info-box">
      <Button.Close
        className="node-palette-info-box__close-btn"
        onClick={() => writeStorage(infoBoxStorageKey, false)}
      />
      <NodePaletteDragHint />
    </div>
  );

  function getNodesOfType(type: NodeType) {
    return (
      <div className="node-palette__list" role="list">
        {nodes
          .filter((node) => node.nodeType === type)
          .map((node, index) => (
            <NodePaletteItem key={node.id} index={index} node={node} shouldShowDragHint={!isInfoBoxOpen} />
          ))}
      </div>
    );
  }

  return (
    <div className="node-palette">
      <div className="node-palette__header">
        <Heading level={2} displayLevel={5}>
          {I18n.t('node_palette.create_flow')}
        </Heading>
      </div>
      <Tabs isVertical hasInsetFocusStyle defaultIndex={null}>
        <Tabs.List>
          <Tabs.Tab className="node-palette__tab">
            <SourceIcon />
            <div className="node-palette__tab-label">{I18n.t('node.source')}</div>
          </Tabs.Tab>
          <Tabs.Tab className="node-palette__tab">
            <TransformationIcon />
            <div className="node-palette__tab-label">{I18n.t('node.transformation')}</div>
          </Tabs.Tab>
          <Tabs.Tab className="node-palette__tab">
            <DestinationIcon />
            <div className="node-palette__tab-label">{I18n.t('node.destination')}</div>
          </Tabs.Tab>
        </Tabs.List>
        {isInfoBoxOpen && infoBox}
        <div className="node-palette__tab-panels">
          <Tabs.Panels>
            <Tabs.Panel className="node-palette__tab-panel">{getNodesOfType(NodeType.Source)}</Tabs.Panel>
            <Tabs.Panel className="node-palette__tab-panel">{getNodesOfType(NodeType.Transformation)}</Tabs.Panel>
            <Tabs.Panel className="node-palette__tab-panel">{getNodesOfType(NodeType.Destination)}</Tabs.Panel>
          </Tabs.Panels>
        </div>
      </Tabs>
    </div>
  );
}
