import React from 'react';
import classNames from 'classnames';
import { FallbackIcon } from './nodeIcons';
import './NodeIcon.scss';

interface Props {
  icon?: string;
  className?: string;
  displayName?: string;
}

export default function NodeIcon(props: Props) {
  const { icon, className, displayName } = props;

  const isValidSvg = icon && icon.startsWith('<svg') && icon.endsWith('</svg>');
  const rootClasses = classNames('node-icon', className, { 'node-icon__script': displayName === 'Script' });

  if (!isValidSvg) {
    return (
      <div className={rootClasses}>
        <FallbackIcon />
      </div>
    );
  }

  return <div className={rootClasses} dangerouslySetInnerHTML={{ __html: icon as string }} />;
}
