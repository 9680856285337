import React from 'react';
import BubblyError from '@acl-services/wasabicons/lib/BubblyError';
import NotificationCard from '@paprika/notification-card';
import { useI18n } from '@paprika/l10n';

import './BubblyErrorInstructions.scss';

export default function BubblyErrorInstructions() {
  const I18n = useI18n();

  return (
    <div className="flow-bubbly-error">
      <NotificationCard maxWidth="500px">
        <NotificationCard.Image>
          <BubblyError size="100%" />
        </NotificationCard.Image>
        <NotificationCard.Header level={2}>{I18n.t('bubbly.error_heading')}</NotificationCard.Header>
        <NotificationCard.Body
          dangerouslySetInnerHTML={{
            __html: I18n.t('bubbly.error_description'),
          }}
        />
      </NotificationCard>
    </div>
  );
}
