import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FlowNodeData from 'types/FlowNodeData';
import Table from 'types/Table';

const flowNodeDataSlice = createSlice({
  name: 'flowNodeData',
  initialState: {} as { [key: string]: FlowNodeData },
  reducers: {
    updateFlowNodeData(state, action: PayloadAction<{ id: string; flowNodeData: FlowNodeData }>) {
      const { id, flowNodeData } = action.payload;
      state[id] = flowNodeData;
    },
    updateFlowNodeDataOutput(state, action: PayloadAction<{ id: string; outputTable: Table; log: string }>) {
      const { id, outputTable, log } = action.payload;
      state[id].outputTable = outputTable;
      state[id].log = log;
      state[id].isPendingOutput = false;
    },
  },
});

export const { updateFlowNodeData, updateFlowNodeDataOutput } = flowNodeDataSlice.actions;

export default flowNodeDataSlice.reducer;
