import * as React from 'react';
import { PointModel } from '@projectstorm/react-diagrams-core';
import {
  FOCUS_LINK_ARROW_COLOUR,
  INPUT_LINK_COLOUR,
  OUTPUT_LINK_COLOUR,
  FOCUS_LINK_INPUT_ARROW_COLOUR,
} from '../constants';

interface Props {
  color?: string;
  colorSelected?: string;
  point: PointModel;
  previousPoint: PointModel;
  selected: boolean;
  hasFocus: boolean;
}

export default function ArrowHead(props: Props) {
  const { color, colorSelected, hasFocus, point, previousPoint, selected } = props;

  const dampener = 0.25;
  const angle =
    90 +
    ((Math.atan2(
      point.getPosition().y - previousPoint.getPosition().y,
      point.getPosition().x - previousPoint.getPosition().x,
    ) *
      180) /
      Math.PI) *
      dampener;

  const isDashed = color === OUTPUT_LINK_COLOUR;
  let fillColour = '';

  if (selected || isDashed) {
    fillColour = colorSelected!;
  } else if (hasFocus) {
    fillColour = FOCUS_LINK_ARROW_COLOUR;
  } else {
    fillColour = color!;
  }

  if (color === INPUT_LINK_COLOUR && hasFocus) {
    fillColour = FOCUS_LINK_INPUT_ARROW_COLOUR;
  }

  return (
    <>
      {selected && (
        <g
          className="flow-link__arrow"
          transform={'translate(' + point.getPosition().x + ', ' + point.getPosition().y + ')'}
        >
          <g style={{ transform: 'rotate(' + angle + 'deg)' }}>
            <g transform={'translate(0, -12.5)'}>
              <polygon points="0,6 12,26 -12,26" fill="#99CBFC"></polygon>
            </g>
            <g transform={'translate(0, -12.5)'}>
              <rect x="-2" y="22" width="4" height="5" fill={fillColour}></rect>
            </g>
          </g>
        </g>
      )}
      <g
        className="flow-link__arrow"
        transform={'translate(' + point.getPosition().x + ', ' + point.getPosition().y + ')'}
      >
        <g style={{ transform: 'rotate(' + angle + 'deg)' }}>
          <g transform={'translate(0, -10.5)'}>
            <polygon points="0,8 8,22 -8,22" fill={fillColour}></polygon>
          </g>
        </g>
      </g>
    </>
  );
}
