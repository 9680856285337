import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import RobotApiService from 'services/RobotApiService';
import Robot from 'types/Robot';

export const fetchRobot = createAsyncThunk(
  'users/fetchRobot',
  async ({ orgId, robotId }: { orgId: string; robotId: string }, { rejectWithValue }) => {
    const api = new RobotApiService();
    try {
      return await api.getRobot(orgId, robotId);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

type RobotState = {
  robot: Robot;
  error?: number;
};

const robotSlice = createSlice({
  name: 'robot',
  initialState: { robot: {}, error: undefined } as RobotState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchRobot.fulfilled, (state, { payload }) => {
      state.robot = payload;
      state.error = undefined;
    });
    builder.addCase(fetchRobot.rejected, (state, { payload: error }) => {
      state.error = error as number;
    });
  },
});

export default robotSlice.reducer;
