import React from 'react';
import Spinner from '@paprika/spinner';
import { useI18n } from '@paprika/l10n';
import './FullscreenSpinner.scss';

export default function FullscreenSpinner() {
  const I18n = useI18n();

  return (
    <div className="fullscreen-spinner">
      <Spinner size="large" a11yText={I18n.t('spinner_alert')} />
    </div>
  );
}
