import React from 'react';
import { DiagramEngine, LabelModel } from '@projectstorm/react-diagrams';
import { AbstractReactFactory, BaseModelOptions, GenerateWidgetEvent } from '@projectstorm/react-canvas-core';
import { FlowLinkModel } from '../models/linkModel';
import FlowLabel from '../FlowLabel/FlowLabel';

interface FlowLabelOptions extends BaseModelOptions {
  linkModel: FlowLinkModel;
}

export class FlowLabelModel extends LabelModel {
  linkModel: FlowLinkModel;

  constructor(options: FlowLabelOptions) {
    super({
      ...options,
      type: 'FlowLabel',
    });
    this.linkModel = options.linkModel!;
  }
}

export class FlowLabelFactory extends AbstractReactFactory<FlowLabelModel, DiagramEngine> {
  constructor() {
    super('FlowLabel');
  }

  // generateModel() is required but never used
  generateModel(): FlowLabelModel {
    // @ts-ignore
    return new FlowLabelModel();
  }

  generateReactWidget(event: GenerateWidgetEvent<FlowLabelModel>): JSX.Element {
    return <FlowLabel labelModel={event.model} engine={this.engine} />;
  }
}
