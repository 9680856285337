import SourceIcon from '@acl-services/wasabicons/lib/ArrowDownCircleLine';
import DestinationIcon from '@acl-services/wasabicons/lib/ArrowUpCircleLine';
import InProgressIcon from '@acl-services/wasabicons/lib/Refresh';
import CompletedIcon from '@acl-services/wasabicons/lib/CheckCircle';
import ErrorIcon from '@acl-services/wasabicons/lib/ExclamationCircle';
import StaleIcon from '@acl-services/wasabicons/lib/RefreshCircle';
import { NodeType, NodeState } from '../types';

export { default as FallbackIcon } from '@acl-services/wasabicons/lib/HighbondLogo';

export const typeIcons = {
  [NodeType.Source]: SourceIcon,
  [NodeType.Destination]: DestinationIcon,
};

export const stateIcons = {
  [NodeState.InProgress]: InProgressIcon,
  [NodeState.Completed]: CompletedIcon,
  [NodeState.Error]: ErrorIcon,
  [NodeState.Stale]: StaleIcon,
};
