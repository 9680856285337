import tokens from '@paprika/tokens';

export const GRID_SIZE = 16;
export const NODE_SIZE = 80;
export const DROP_NODE_EVENT = 'node-palette-drop';
export const ZOOM_PERCENTAGES = [25, 50, 67, 75, 80, 90, 100, 110, 125, 150, 175, 200, 250];
export const ZOOM_TO_FIT_MARGIN = 50;
export const OUTPUT_LINK_COLOUR = 'dashed-blue';
export const INPUT_LINK_COLOUR = tokens.color.blackLighten20;
export const FOCUS_LINK_ARROW_COLOUR = '#8C99B4';
export const FOCUS_LINK_INPUT_ARROW_COLOUR = '#82B8FB';
