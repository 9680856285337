enum NodeState {
  Loading = 'loading',
  Ready = 'ready',
  InProgress = 'in-progress',
  Completed = 'completed',
  Stale = 'stale',
  Error = 'error',
}

export default NodeState;
