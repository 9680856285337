import { JwtHighbondClient } from '@acl-services/jwt-highbond-client';
import { isLocalDev } from 'localDev/localDevHelpers';
import { getApiProxyUrl, getAccountUrl, getRegion } from 'utils/UrlBuilder';
import Jsona, { SwitchCaseJsonMapper, SwitchCaseModelMapper } from 'jsona';

const jsona = new Jsona({
  modelPropertiesMapper: new SwitchCaseModelMapper({
    switchChar: '_',
  }),
  jsonPropertiesMapper: new SwitchCaseJsonMapper({
    switchChar: '_',
  }),
});

export default class BaseApiService {
  protected jwtClient: JwtHighbondClient;

  protected ACCOUNTS_URL = getAccountUrl(window.location.origin);
  protected API_PROXY = getApiProxyUrl(window.location.origin);
  protected REGION = getRegion(window.location.origin);

  constructor() {
    this.jwtClient = new JwtHighbondClient({
      tokenRefreshUrl: `${isLocalDev() ? '/accounts' : this.ACCOUNTS_URL}/api/token/refresh`,
      noSubdomain: isLocalDev(),
    });
  }

  async fetchWithTokenRefresh(path: string, options: any = {}): Promise<Response> {
    let opts = { credentials: 'include', ...options };
    return await this.jwtClient.fetchWithTokenRefresh(`${this.API_PROXY}${path}`, opts);
  }

  deserialize(json) {
    return jsona.deserialize(json);
  }
}
