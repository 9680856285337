export enum ExecutionResultType {
  Output = 'output',
  Status = 'status',
}

export enum ExecutionOutput {
  Text = 'text',
  Html = 'html',
  Error = 'error',
}

export enum ExecutionStatus {
  ExecutionStart = 'executionStart',
  ExecutionEnd = 'executionEnd',
  ExecutionComplete = 'executionComplete',
}
