import React from 'react';
import OverflowIcon from '@acl-services/wasabicons/lib/Ellipsis';
import Confirmation from '@paprika/confirmation';
import DropdownMenu from '@paprika/dropdown-menu';
import { useI18n } from '@paprika/l10n';
import { shortcutKeys, modifierSymbols } from './useShortcutKeys';
import './ScriptCellDropdown.scss';

type Props = {
  isDisabled: boolean;
  onAddCellBelow?: () => void;
  onDeleteCell: () => void;
};

export default function ScriptCellDropdown({ isDisabled, onAddCellBelow, onDeleteCell }: Props) {
  const I18n = useI18n();

  function renderListItem(label: string, shortcutCombo?: string[]): JSX.Element {
    return (
      <div className="script-node-cell__overflow-item">
        <div className="script-node-cell__overflow-item-label">{label}</div>
        {shortcutCombo && (
          <div className="script-node-cell__overflow-item-shortcut-key">
            {shortcutCombo.map((key) => (key in modifierSymbols ? modifierSymbols[key] : key.toUpperCase()))}
          </div>
        )}
      </div>
    );
  }

  return (
    <DropdownMenu zIndex={9} edge="left" className="script-node-cell__overflow">
      <DropdownMenu.Trigger
        a11yText={I18n.t('script_node.cell.actions_dropdown')}
        buttonType="icon"
        data-pendo-anchor="cell-overflow__trigger"
        isDisabled={isDisabled}
        kind="minor"
      >
        <OverflowIcon />
      </DropdownMenu.Trigger>
      <DropdownMenu.Item data-pendo-anchor="cell-overflow__add-cell-below" onClick={onAddCellBelow}>
        {renderListItem(I18n.t('script_node.cell.add_cell_below'), shortcutKeys.ADD)}
      </DropdownMenu.Item>
      <DropdownMenu.Divider />
      <DropdownMenu.Item
        data-pendo-anchor="cell-overflow__remove-cell"
        isDestructive
        renderConfirmation={(onCloseMenu) => {
          return (
            <Confirmation
              confirmLabel={I18n.t('script_node.cell.delete_confirmation_label')}
              heading={I18n.t('script_node.cell.delete_confirmation_heading')}
              onConfirm={onDeleteCell}
              onClose={onCloseMenu}
            />
          );
        }}
      >
        {renderListItem(I18n.t('script_node.cell.delete_cell'), shortcutKeys.DELETE)}
      </DropdownMenu.Item>
    </DropdownMenu>
  );
}
