import React from 'react';
import tokens from '@paprika/tokens';
import Popover from '@paprika/popover';
import Button from '@paprika/button';
import InfoIcon from '@acl-services/wasabicons/lib/InfoCircle';
import './InfoTooltip.scss';

type Props = {
  a11yText?: string;
  content: string;
  maxWidth?: number;
};

export default function InfoTooltip({ a11yText, content, maxWidth }: Props) {
  return (
    <Popover maxWidth={maxWidth} offset={8} zIndex={9} className="info-tooltip">
      <Popover.Trigger>
        {(onClickHandler, a11yAttributes) => {
          return (
            <Button.Icon
              a11yText={a11yText}
              onClick={onClickHandler}
              {...a11yAttributes}
              kind={Button.types.kind.MINOR}
              size={Button.types.size.SMALL}
            >
              <InfoIcon color={tokens.textColor.icon} size="20px" />
            </Button.Icon>
          );
        }}
      </Popover.Trigger>
      <Popover.Content className="info-tooltip__content">
        <Popover.Card>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </Popover.Card>
      </Popover.Content>
      <Popover.Tip />
    </Popover>
  );
}
