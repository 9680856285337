import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import UserApiService from 'services/UserApiService';
import User from 'types/User';

export const fetchSystemUsers = createAsyncThunk(
  'users/fetchSystemUsers',
  async ({ orgId, driveSystemUser }: { orgId: string; driveSystemUser: string }) => {
    const api = new UserApiService();
    const systemUsers = api.getSystemUsers(orgId, driveSystemUser);
    return systemUsers;
  },
);

type UsersState = {
  systemUsers: User[];
  error?: string;
};

const usersSlice = createSlice({
  name: 'users',
  initialState: { systemUsers: [], error: undefined } as UsersState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSystemUsers.fulfilled, (state, { payload }) => {
      state.systemUsers = payload;
      state.error = undefined;
    });
    builder.addCase(fetchSystemUsers.rejected, (state, action) => {
      state.error = action.error.message;
    });
  },
});

export default usersSlice.reducer;
