import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Variable from 'types/Variable';

function getVariable(variableId, state) {
  const variable = state.find((variable) => {
    return variable.id === variableId;
  });
  if (!variable) {
    throw new Error(`could not find variable with id: ${variableId}`);
  }
  return variable;
}

const variablesSlice = createSlice({
  name: 'variables',
  initialState: [] as Variable[],
  reducers: {
    importVariables(state, action: PayloadAction<Variable[]>) {
      return action.payload;
    },
    addVariable(state, action: PayloadAction<Variable>) {
      state.push({ ...action.payload });
    },
    removeVariable(state, action: PayloadAction<string>) {
      const varToRemove = getVariable(action.payload, state);
      state.splice(state.indexOf(varToRemove), 1);
    },
    updateVariable(state, action: PayloadAction<Variable>) {
      const varToUpdate = getVariable(action.payload.id, state);
      const index = state.indexOf(varToUpdate);
      state[index] = action.payload;
    },
  },
});

export const { addVariable, importVariables, removeVariable, updateVariable } = variablesSlice.actions;

export default variablesSlice.reducer;
