import querystring from 'querystring';
import { isStorybook } from 'localDev/localDevHelpers';
import MessageAction from 'enums/MessageAction';
import Message from 'types/Message';

export default class WebSocketService {
  private webSocket;
  private webSocketUrl;
  private sessionId;
  private robotId;
  private driveSystemUser;
  private messageListeners = new Map<string, Function[]>();
  private onOpenHandler;
  private onCloseHandler;
  private _isConnected = false;
  private hasExistingSession = false;

  constructor(webSocketUrl: string, sessionId: string, robotId: string, driveSystemUser: string) {
    if (isStorybook()) return;

    console.log(new Date().toLocaleTimeString(), 'Using websocket URL', webSocketUrl);
    this.webSocketUrl = webSocketUrl;
    this.sessionId = sessionId;
    this.robotId = robotId;
    this.driveSystemUser = driveSystemUser;
    this.addMessageListener(MessageAction.ServiceReady, () => {
      this._isConnected = true;
      this.hasExistingSession = true;
    });
    this.initWebSocket();
  }

  initWebSocket() {
    console.log(new Date().toLocaleTimeString(), 'session ID: ', this.sessionId);

    // TODO: get token from environment variable
    const params = {
      sessionId: this.sessionId,
      token: 'foobar',
      robotId: this.robotId,
      driveSystemUser: this.driveSystemUser,
    };
    const url = `${this.webSocketUrl}?${querystring.stringify(params)}`;
    this.webSocket = new WebSocket(url);

    this.webSocket.onopen = this.onOpen;
    this.webSocket.error = (err) => console.log(err);
    this.webSocket.onmessage = this.receiveMessage;
    this.webSocket.onclose = this.onClose;
  }

  private onOpen = () => {
    console.log(new Date().toLocaleTimeString(), 'websocket connected');
    this._isConnected = this.hasExistingSession;
    this.onOpenHandler();
  };

  private onClose = async () => {
    console.log(new Date().toLocaleTimeString(), 'Websocket closed!');
    this._isConnected = false;
    this.onCloseHandler();
  };

  private receiveMessage = (event: MessageEvent) => {
    const msg = JSON.parse(event.data);
    console.log(new Date().toLocaleTimeString(), 'RECEIVED: ', msg);

    if (msg.data) {
      const handlers = this.messageListeners.get(msg.data.action);
      if (handlers) {
        handlers.forEach((handler) => handler(msg.data.body));
      }
    } else if (msg.error) {
        const handlers = this.messageListeners.get('error');
        if (handlers) {
          handlers.forEach((handler) => handler(msg.error));
        }
    } else {
      console.log(new Date().toLocaleTimeString(), 'Invalid response!');
    }
  };

  isConnected() {
    return this._isConnected;
  }

  sendMessage(message: Message) {
    const jsonMsg = JSON.stringify(message);
    if (this.webSocket && this._isConnected) {
      console.log(new Date().toLocaleTimeString(), 'SENT', jsonMsg);
      this.webSocket.send(jsonMsg);
    } else {
      console.log(new Date().toLocaleTimeString(), 'Websocket connection not ready!', jsonMsg);
    }
  }

  setOnCloseHandler(handler: Function) {
    this.onCloseHandler = handler;
  }

  setOnOpenHandler(handler: Function) {
    this.onOpenHandler = handler;
  }

  addMessageListener = (action: string, handler: Function) => {
    if (!this.messageListeners.has(action)) {
      this.messageListeners.set(action, []);
    }
    this.messageListeners.get(action)!.push(handler);
    return () => this.removeMessageListener(action, handler);
  };

  removeMessageListener = (action: string, handler: Function) => {
    const handlers = this.messageListeners.get(action);
    if (handlers) {
      const handlerToRemove = handlers.find((h) => h === handler);
      if (handlerToRemove) {
        const index = handlers.indexOf(handlerToRemove);
        handlers.splice(index, 1);
      }
    }
  };
}
