import UserType from 'enums/UserType';
import User from 'types/User';
import BaseApiService from './BaseApiService';

export default class UserApiService extends BaseApiService {
  async getSystemUsers(orgId, driveSystemUser): Promise<User[]> {
    const systemUserResponse = await this.fetchWithTokenRefresh(`/v1/orgs/${orgId}/system_users`, {
      headers: { 'Content-Type': 'application/vnd.api+json' },
    });
    if (systemUserResponse.ok) {
      const systemUsersJson = await systemUserResponse.json();
      const systemUsers = this.deserialize(systemUsersJson);
      return this.filterSystemUsers(systemUsers, driveSystemUser) as User[];
    }
    return Promise.reject(systemUserResponse.status);
  }

  private filterSystemUsers(users, driveSystemUser) {
    return users.filter((user) => user.userType === UserType.System && user.id !== driveSystemUser);
  }
}
