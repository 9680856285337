import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ErrorBoundary } from 'react-error-boundary';
import tokens from '@paprika/tokens';
import createEngine, {
  DiagramEngine,
  DiagramModel,
  DefaultDiagramState,
  DiagramListener,
  NodeModelListener,
  PortModelAlignment,
} from '@projectstorm/react-diagrams';
import { CanvasWidget, BaseModelListener } from '@projectstorm/react-canvas-core';
import L10n from '@paprika/l10n';
import { useI18n } from '@paprika/l10n';
import Locales from './locales';
import { PartialLink, Node, NodeType, InputEdge, DiagramNode, Position, FlowEvent } from './types';
import { GRID_SIZE, OUTPUT_LINK_COLOUR, NODE_SIZE, DROP_NODE_EVENT, INPUT_LINK_COLOUR } from './constants';
import { FlowNodeModel, FlowNodeFactory } from './models/nodeModel';
import { FlowPortModel, FlowPortFactory } from './models/portModel';
import { FlowLinkModel, FlowLinkFactory } from './models/linkModel';
import { FlowLabelModel, FlowLabelFactory } from './models/labelModel';
import { NodeContext } from './CanvasNode/CanvasNode';
import { EdgeContext } from './FlowLink/FlowLinkSegment';
import { createLink, createNode, findNode, normalizeNodes, roundNumberForGrid } from './flowDiagramHelpers';
import useBackgroundListeners from './hooks/useBackgroundListeners';
import useFlowDiagramHandle from './hooks/useFlowDiagramHandle';
import DiagramSpinner from './DiagramSpinner/DiagramSpinner';
import BubblyInstructions from './BubblyInstructions/BubblyInstructions';
import BubblyErrorInstructions from './BubblyErrorInstructions/BubblyErrorInstructions';
import './FlowDiagram.scss';

/** Note: edge == link
 * In this component, the connection between nodes is referred to as an "edge"
 * where it is exposed to the consuming application, but is aligned with the
 * react-diagrams term, "link", where the implementation is strictly internal.
 */

interface Props {
  defaultEdges: InputEdge[];
  defaultNodes: DiagramNode[];
  onRemoveEdge(linkId: string): void;
  onAddEdge(linkId: string, sourceId: string, targetId: string): void;
  onAddNode(outputNode: DiagramNode): void;
  onMoveNode(nodeId: string, position: Position): void;
  onSelectNode(nodeId: string): void;
  getConnectionValidity?(currentNodeId: string): { [nodeId: string]: boolean };
  onAfterLoad?(): void;
}

function FlowDiagram(
  {
    defaultEdges,
    defaultNodes,
    onRemoveEdge,
    onAddEdge,
    onMoveNode,
    onSelectNode,
    onAddNode,
    getConnectionValidity,
    onAfterLoad,
  }: Props,
  ref,
) {
  const [theEngine, setEngine] = React.useState<DiagramEngine | null>(null);
  const [isCreatingLink, setCreatingLink] = React.useState<boolean>(false);
  const [nodes, setNodes] = React.useState<{ [nodeId: string]: Node }>({});
  const [edges, setEdges] = React.useState<{ [linkId: string]: PartialLink }>({});
  const newLinkRef = React.useRef<FlowLinkModel | null>(null);
  const movedNodeRef = React.useRef<FlowNodeModel | null>(null);
  const backgroundRef = React.useRef<HTMLDivElement | null>(null);
  const I18n = useI18n();

  const canvasEngineOptions = {
    registerDefaultDeleteItemsAction: false,
    registerDefaultZoomCanvasAction: false,
  };

  const addLinkListeners = React.useCallback(
    (newLink: FlowLinkModel) => {
      newLink.registerListener({
        entityRemoved: (event: FlowEvent<FlowLinkModel>) => {
          const link = event.entity;
          onRemoveEdge(link.getOptions().extras.linkId!);
        },
        selectionChanged: (event: FlowEvent<FlowLinkModel>) => {
          if (event.isSelected) {
            showDeletePopover(event.entity);
            deselectOtherEntities(event.entity);
          }

          if (!event.isSelected && !event.entity.getOptions().extras.isPopoverOpen) {
            hideDeletePopover(event.entity);
          }
        },
      } as BaseModelListener);
    },
    [onRemoveEdge],
  );

  const handleAddEdge = React.useCallback(() => {
    const newLink = newLinkRef.current;
    const hasPorts = newLink && newLink.getSourcePort() && newLink.getTargetPort();

    if (hasPorts) {
      const sourceNode = newLink!.getSourcePort().getParent() as FlowNodeModel;
      const targetNode = newLink!.getTargetPort().getParent() as FlowNodeModel;
      const linkId = newLink!.getOptions().id!;
      const newLinkSourceNodeId = sourceNode.getOptions().extras.nodeId!;
      const newLinkTargetNodeId = targetNode.getOptions().extras.nodeId!;

      const sourceNodeName = nodes[newLinkSourceNodeId].name;
      const targetNodeName = nodes[newLinkTargetNodeId].name;
      const ariaLabel = I18n.t('edge.a11y', { sourceNode: sourceNodeName, targetNode: targetNodeName });

      newLink?.setSelected(false);
      newLink!.getOptions().extras.linkId = linkId;

      setEdges((prevEdges) => ({
        ...prevEdges,
        [linkId]: {
          ariaLabel: ariaLabel,
        },
      }));

      addLinkListeners(newLink!);
      if (sourceNode.isSelected()) {
        colourConnectedLinks(sourceNode);
      }
      if (targetNode.isSelected()) {
        colourConnectedLinks(targetNode);
      }
      onAddEdge(newLink?.getOptions().extras.linkId!, newLinkSourceNodeId, newLinkTargetNodeId);
    }
  }, [I18n, addLinkListeners, nodes, onAddEdge]);

  const handleMoveNode = React.useCallback(() => {
    const node = movedNodeRef.current;
    if (movedNodeRef.current !== null) {
      if (node) {
        onMoveNode(node?.getOptions().extras.nodeId!, { x: node?.getPosition().x, y: node?.getPosition().y });
      }
      movedNodeRef.current = null;
    }
  }, [onMoveNode]);

  const resetDisabledNodes = React.useCallback(() => {
    const nextNodes = { ...nodes };
    for (const nodeId in nextNodes) {
      nextNodes[nodeId].isDisabled = false;
    }
    setNodes(nextNodes);
  }, [nodes]);

  const handleMouseUp = React.useCallback(() => {
    return setTimeout(() => {
      if (isCreatingLink) {
        handleAddEdge();
        resetDisabledNodes();
      }

      handleMoveNode();
      setCreatingLink(false);
    });
  }, [handleAddEdge, handleMoveNode, isCreatingLink, resetDisabledNodes]);

  const getEngineModel = React.useCallback(() => {
    return theEngine && theEngine.getModel();
  }, [theEngine]);

  const repaint = React.useCallback(() => {
    if (theEngine) theEngine.repaintCanvas();
  }, [theEngine]);

  function normalizeEdges(engineModel, nodes) {
    const normalizedEdges = {} as { [linkId: string]: PartialLink };

    for (const edge of defaultEdges) {
      const { id, sourceId, targetId } = edge;

      const sourceNode = findNode(engineModel, sourceId).getOptions().extras.nodeId;
      const targetNode = findNode(engineModel, targetId).getOptions().extras.nodeId;

      const ariaLabel = I18n.t('edge.a11y', { sourceNode: nodes[sourceNode].name, targetNode: nodes[targetNode].name });

      normalizedEdges[id] = { ...normalizedEdges[id], ariaLabel: ariaLabel };
    }

    return normalizedEdges;
  }

  function repositionLinkEndpoint(link: FlowLinkModel) {
    const endPoint = link.getLastPoint().getPosition();
    const isEndPointZero = endPoint.x === 0 && endPoint.y === 0;

    if (isEndPointZero) {
      const portPosition = link.getPortForPoint(link.getFirstPoint()).getPosition();
      link.getLastPoint().setPosition(portPosition);
    }
  }

  const disableInvalidNodes = React.useCallback(
    (link: FlowLinkModel) => {
      if (!getConnectionValidity) return;
      const currentNodeId = link.getSourcePort().getParent().getOptions().extras.nodeId;
      const nodeValues = getConnectionValidity(currentNodeId);
      if (nodeValues) {
        const nextNodes = { ...nodes };
        for (const nodeId in nextNodes) {
          if (currentNodeId !== nodeId) {
            nextNodes[nodeId].isDisabled = nodeValues[nodeId] === false;
          }
        }
        setNodes(nextNodes);
      }
    },
    [getConnectionValidity, nodes],
  );

  const handleCreateLink = React.useCallback(
    (event: FlowEvent) => {
      if (event.isCreated) {
        const newLink = event.link;
        if (!newLink) return;
        newLinkRef.current = newLink;
        setCreatingLink(event.firing);
        repositionLinkEndpoint(newLink);
        disableInvalidNodes(newLink);
      }
    },
    [disableInvalidNodes],
  );

  function handleDropNode(event: React.DragEvent<HTMLDivElement>) {
    const id = uuidv4();
    const point = theEngine!.getRelativeMousePoint(event);
    const position = { x: roundNumberForGrid(point.x), y: roundNumberForGrid(point.y) };
    position.x = position.x - NODE_SIZE / 2;
    position.y = position.y - NODE_SIZE / 2;
    const { libraryNodeId, nodeType, specialNodeKind, icon, backgroundColor, displayName } = JSON.parse(
      event.dataTransfer?.getData(DROP_NODE_EVENT) || '',
    );

    if (!(nodeType in NodeType)) return;

    const diagramNode: DiagramNode = {
      id,
      libraryNodeId,
      type: nodeType,
      name: displayName,
      kind: specialNodeKind,
      icon,
      backgroundColor,
      position,
    };

    const nodeModel = createNode(diagramNode);

    nodeModel.registerListener({
      positionChanged: handlePositionChanged,
      selectionChanged: handleNodeSelection,
    } as NodeModelListener);

    setNodes((prevNodes) => ({
      ...prevNodes,
      [id]: {
        model: nodeModel,
        name: displayName,
      },
    }));

    onAddNode({ ...diagramNode, libraryNodeId });
    getEngineModel()?.addNode(nodeModel);
    repaint();
  }

  function handlePositionChanged(event: FlowEvent<FlowNodeModel>) {
    movedNodeRef.current = event.entity as FlowNodeModel;
  }

  function showDeletePopover(linkModel: FlowLinkModel) {
    if (linkModel.getLabels().length < 1) {
      linkModel.addLabel(new FlowLabelModel({ linkModel }));
    }
  }

  function hideDeletePopover(linkModel: FlowLinkModel) {
    linkModel.getOptions().extras.isPopoverOpen = false;
    linkModel.getLabels().length = 0;
  }

  function resetLinkColours(nodeModel: FlowNodeModel) {
    const linkModels = nodeModel
      .getParentCanvasModel()
      .getModels()
      .filter((model) => model instanceof FlowLinkModel) as FlowLinkModel[];

    linkModels.forEach((link) => {
      link.setColor(tokens.color.blackLighten40);
    });
  }

  function colourConnectedLinks(nodeModel: FlowNodeModel) {
    const inputLinks = nodeModel.getPorts().left?.getLinks();
    const outputLinks = nodeModel.getPorts().right?.getLinks();

    if (inputLinks) {
      Object.keys(inputLinks).forEach((internalLinkId) => {
        const inputLink = inputLinks[internalLinkId] as FlowLinkModel;
        inputLink.setColor(INPUT_LINK_COLOUR);
      });
    }

    if (outputLinks) {
      Object.keys(outputLinks).forEach((internalLinkId) => {
        const outputLink = outputLinks[internalLinkId] as FlowLinkModel;
        outputLink.setColor(OUTPUT_LINK_COLOUR);
      });
    }
  }

  function deselectOtherEntities(entity: FlowNodeModel | FlowLinkModel) {
    const entitiesToDeselect = entity
      .getParentCanvasModel()
      .getModels()
      .filter((_entity) => _entity.isSelected() && _entity !== entity);
    entitiesToDeselect.forEach((_entity) => {
      _entity.setSelected(false);
    });
  }

  function handleNodeSelection(event: FlowEvent<FlowNodeModel>) {
    const node = event.entity;

    if (event.isSelected) {
      deselectOtherEntities(node);
      colourConnectedLinks(node);
      onSelectNode(node.getOptions().extras.nodeId!);
    } else {
      resetLinkColours(node);
    }
  }

  // For initializeDiagram()
  const initEngine = React.useRef<DiagramEngine>(createEngine(canvasEngineOptions)).current;
  const initModel = React.useRef<DiagramModel>(new DiagramModel()).current;
  const initPorts = React.useRef<{ [nodeId: string]: FlowPortModel }>({}).current;

  function createNodes(nodes: DiagramNode[]) {
    for (const node of nodes) {
      if (!node.id || !node.type) return;

      const nodeModel = createNode(node);
      nodeModel.registerListener({
        positionChanged: handlePositionChanged,
        selectionChanged: handleNodeSelection,
      } as NodeModelListener);

      initModel.addNode(nodeModel);

      const inPort = nodeModel.getPort(PortModelAlignment.LEFT);
      const outPort = nodeModel.getPort(PortModelAlignment.RIGHT);
      if (inPort) initPorts[`in-${node.id}`] = inPort as FlowPortModel;
      if (outPort) initPorts[`out-${node.id}`] = outPort as FlowPortModel;
    }
  }

  function connectLinks(links: InputEdge[]) {
    for (const link of links) {
      const sourcePort = initPorts[`out-${link.sourceId}`];
      const targetPort = initPorts[`in-${link.targetId}`];
      if (!sourcePort || !targetPort) return;
      const newLink = createLink(sourcePort, targetPort, link.id);
      addLinkListeners(newLink);
      initModel.addLink(newLink);
    }
    setCreatingLink(false);
  }

  React.useEffect(function initializeDiagram() {
    // Initialize engine
    const state = initEngine.getStateMachine().getCurrentState();
    if (state instanceof DefaultDiagramState) {
      // @ts-ignore
      // Removes the shift select highlight box
      state.childStates = [];
      state.dragNewLink.config.allowLooseLinks = false;
    }
    initEngine.setMaxNumberPointsPerLink(0);
    for (const nodeType in NodeType) {
      initEngine.getNodeFactories().registerFactory(new FlowNodeFactory(nodeType));
    }
    initEngine.getLabelFactories().registerFactory(new FlowLabelFactory());
    initEngine.getPortFactories().registerFactory(new FlowPortFactory());
    initEngine.getLinkFactories().registerFactory(new FlowLinkFactory());
    // Initialize nodes + links
    createNodes(defaultNodes);
    connectLinks(defaultEdges);

    const normalizedNodes = normalizeNodes(initModel.getNodes(), defaultNodes);

    // Set state
    setNodes(normalizedNodes);
    initEngine.setModel(initModel);
    initEngine.getModel().setGridSize(GRID_SIZE);
    setEngine(initEngine);
    const normalizedEdges = normalizeEdges(initModel, normalizedNodes);
    setEdges(normalizedEdges);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(
    function handleAfterLoad() {
      if (theEngine && onAfterLoad) {
        onAfterLoad();
      }
    },
    [theEngine], // eslint-disable-line react-hooks/exhaustive-deps
  );

  // add listeners
  React.useEffect(
    function addModelListeners() {
      const linkListener = {
        linksUpdated: handleCreateLink,
      } as DiagramListener;

      getEngineModel()?.registerListener(linkListener);
      return () => {
        getEngineModel()?.getListenerHandle(linkListener).deregister();
      };
    },
    [handleCreateLink, getEngineModel],
  );

  React.useEffect(
    function addMouseListener() {
      document.addEventListener('mouseup', handleMouseUp);
      return () => {
        document.removeEventListener('mouseup', handleMouseUp);
      };
    },
    [handleMouseUp],
  );

  useBackgroundListeners(backgroundRef, getEngineModel);
  useFlowDiagramHandle({
    addLinkListeners,
    engine: theEngine,
    nodes,
    onRemoveEdge,
    ref,
    repaint,
    setNodes,
    setEdges,
    I18n,
  });

  return theEngine ? (
    <div
      className="flow-diagram"
      data-open-ports={isCreatingLink}
      onDrop={handleDropNode}
      onDragOver={(event) => {
        event.preventDefault();
      }}
      ref={backgroundRef}
    >
      <EdgeContext.Provider value={edges}>
        <NodeContext.Provider value={nodes}>
          {Object.keys(nodes).length === 0 ? <BubblyInstructions /> : null}
          <CanvasWidget className="flow-diagram__canvas" engine={theEngine} />
        </NodeContext.Provider>
      </EdgeContext.Provider>
    </div>
  ) : (
    <DiagramSpinner />
  );
}

function FlowDiagramWithL10n(props, ref) {
  const I18n = useI18n();

  return (
    <L10n locale={I18n.locale} locales={Locales}>
      <ErrorBoundary FallbackComponent={BubblyErrorInstructions}>
        <FlowDiagram {...props} ref={ref} />
      </ErrorBoundary>
    </L10n>
  );
}

// @ts-ignore
// eslint-disable-next-line no-func-assign
FlowDiagram = React.forwardRef(FlowDiagram);

export default React.forwardRef<React.ReactElement, Props>(FlowDiagramWithL10n);
