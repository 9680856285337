import React from 'react';
import { DiagramEngine } from '@projectstorm/react-diagrams';
import { FlowPortModel } from '../models/portModel';
import { FlowLinkModel } from '../models/linkModel';
import { PartialLink, Node, PartialNode, FlowDiagramHandle } from '../types';
import { ZOOM_TO_FIT_MARGIN } from '../constants';
import {
  createLink,
  findNode,
  findLink,
  getNextZoomInPercentage,
  getNextZoomOutPercentage,
} from '../flowDiagramHelpers';

interface Params {
  addLinkListeners(newLink: FlowLinkModel): void;
  engine: DiagramEngine | null;
  nodes: { [nodeId: string]: Node };
  onRemoveEdge(linkId: string): void;
  ref: any;
  repaint(): void;
  setNodes(value: React.SetStateAction<{ [nodeId: string]: Node }>): void;
  setEdges(value: React.SetStateAction<{ [linkId: string]: PartialLink }>): void;
  I18n: any;
}

export default function useFlowDiagramHandle({
  addLinkListeners,
  engine,
  nodes,
  onRemoveEdge,
  ref,
  repaint,
  setNodes,
  setEdges,
  I18n,
}: Params) {
  return React.useImperativeHandle(ref, (): FlowDiagramHandle | null => {
    if (!engine) return null;
    const engineModel = engine.getModel();

    function removeLinksfromPort(port) {
      if (port !== undefined) {
        const links = port.getLinks();
        Object.keys(links).forEach((link) => {
          const linkToRemove = links[link];
          engineModel.removeLink(linkToRemove);
          onRemoveEdge(linkToRemove.getOptions().extras.linkId);
        });
      }
    }

    function setLinkLabels(nodeId, nodeName) {
      const nodeModel = engineModel.getNodes().find((node) => node.getOptions().extras.nodeId === nodeId)!;
      const inputLinks = nodeModel.getPorts().left?.getLinks();
      const outputLinks = nodeModel.getPorts().right?.getLinks();

      if (inputLinks) {
        Object.keys(inputLinks).forEach((internalLinkId) => {
          const inputLink = inputLinks[internalLinkId] as FlowLinkModel;
          const sourceNodeId = inputLink.getSourcePort().getParent().getOptions().extras.nodeId;
          const sourceNodeName = nodes[sourceNodeId].name;
          const ariaLabel = I18n.t('edge.a11y', { sourceNode: sourceNodeName, targetNode: nodeName });

          setEdges((prevEdges) => ({
            ...prevEdges,
            [internalLinkId]: {
              ariaLabel: ariaLabel,
            },
          }));
        });
      }

      if (outputLinks) {
        Object.keys(outputLinks).forEach((internalLinkId) => {
          const outputLink = outputLinks[internalLinkId] as FlowLinkModel;
          const targetNodeId = outputLink.getTargetPort().getParent().getOptions().extras.nodeId;
          const targetNodeName = nodes[targetNodeId].name;
          const ariaLabel = I18n.t('edge.a11y', { sourceNode: nodeName, targetNode: targetNodeName });

          setEdges((prevEdges) => ({
            ...prevEdges,
            [internalLinkId]: {
              ariaLabel: ariaLabel,
            },
          }));
        });
      }
    }

    return {
      updateNodeStates: (nodeStates: PartialNode[]): void => {
        const nextNodes = { ...nodes };
        for (const node of nodeStates) {
          if (nextNodes[node.id]) {
            nextNodes[node.id].state = node.state;
          }
        }
        setNodes(nextNodes);
      },
      updateNodeName: (nodeId, nodeName): void => {
        const nextNodes = { ...nodes };
        nextNodes[nodeId].name = nodeName;
        setNodes(nextNodes);
        setLinkLabels(nodeId, nodeName);
      },
      addEdge: (sourceId, targetId): string => {
        const hasMissingPort = !nodes[sourceId]?.model?.getPorts()?.right || !nodes[targetId]?.model?.getPorts()?.left;
        if (hasMissingPort) return '';

        const newLink = createLink(
          nodes[sourceId].model.getPorts().right as FlowPortModel,
          nodes[targetId].model.getPorts().left as FlowPortModel,
        );
        addLinkListeners(newLink);
        if (!newLink) return '';

        engineModel.addLink(newLink);
        return newLink.getOptions().extras.linkId || '';
      },
      removeEdge: (edgeId): void => {
        const linkToRemove = findLink(engineModel, edgeId);

        if (linkToRemove) {
          engineModel.removeLink(linkToRemove);
          repaint();
        }
      },
      removeNode: (nodeId): void => {
        const nodeToRemove = findNode(engineModel, nodeId);

        if (nodeToRemove) {
          removeLinksfromPort(nodeToRemove.getPorts().left);
          removeLinksfromPort(nodeToRemove.getPorts().right);

          engineModel.removeNode(nodeToRemove);
          delete nodes[nodeId];
          repaint();
        }
      },
      selectNode: (nodeId): void => {
        const nodeToSelect = findNode(engineModel, nodeId);
        engineModel.clearSelection();
        repaint();
        nodeToSelect?.setSelected(true);
      },
      zoomIn: (): number => {
        const newZoom = getNextZoomInPercentage(Math.round(engineModel.getZoomLevel()));
        engineModel.setZoomLevel(newZoom);
        repaint();
        return engineModel.getZoomLevel();
      },
      zoomOut: (): number => {
        const newZoom = getNextZoomOutPercentage(Math.round(engineModel.getZoomLevel()));
        engineModel.setZoomLevel(newZoom);
        repaint();
        return engineModel.getZoomLevel();
      },
      zoomToFit: (): number => {
        engine.zoomToFitNodes(ZOOM_TO_FIT_MARGIN);
        return Math.round(engineModel.getZoomLevel()) || 100;
      },
    };
  });
}
