import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Heading from '@paprika/heading';
import { RootState } from 'rootReducer';
import Edge from 'types/Edge';
import FlowNode from 'types/FlowNode';
import Position from 'types/Position';
import flippers from 'utils/flippers';
import { addEdge, removeEdge } from 'slices/EdgesSlice';
import { addFlowNode, moveFlowNode } from 'slices/FlowNodesSlice';
import mockDiagramNodes from 'components/FlowDiagram/mockData/mockDiagramNodes';
import mockLibraryNodes from 'components/FlowDiagram/mockData/mockLibraryNodes';
import FlowDiagram from 'components/FlowDiagram/FlowDiagram';
import { FlowDiagramHandle, DiagramNode } from 'components/FlowDiagram/types';
import NodePalette from 'components/FlowDiagram/NodePalette/NodePalette';
import CanvasToolbar from './CanvasToolbar';
import './CanvasWorkArea.scss';

type Props = {
  onConfigureNode: (node: FlowNode) => void;
  onVariablesButtonClick: () => void;
};

export default function CanvasWorkArea({ onConfigureNode, onVariablesButtonClick }: Props) {
  const nodes: FlowNode[] = useSelector((state: RootState) => state.flowNodes.present);
  const edges: Edge[] = useSelector((state: RootState) => state.edges.present);
  const dispatch = useDispatch();
  const flowDiagramRef = useRef<FlowDiagramHandle>(null);

  useEffect(() => {
    if (!flippers.flowDiagram && nodes.length) {
      onConfigureNode(nodes[0]);
    }
  }, [nodes, onConfigureNode]);

  if (!flippers.flowDiagram) {
    return null;
  }

  function handleRemoveEdge(edgeId) {
    dispatch(removeEdge(edgeId));
  }

  function handleAddEdge(edgeId, sourceId, targetId) {
    const timestamp = Math.floor(Date.now() / 1000);
    dispatch(addEdge({ id: edgeId, sourceId, targetId, timestamp }));
  }

  function handleMoveNode(nodeId: string, position: Position) {
    dispatch(moveFlowNode({ id: nodeId, position }));
  }

  function handleAddNode(node: DiagramNode) {
    const timestamp = Math.floor(Date.now() / 1000);
    const newNode: FlowNode = {
      id: node.id,
      libraryNodeId: node.libraryNodeId,
      name: node.name,
      kind: node.kind,
      position: node.position,
      timestamp,
    };
    dispatch(addFlowNode(newNode));
  }

  const round = (x) => Number.parseFloat(x).toFixed(0);

  return (
    <div className="super-layout">
      <div className="flow-builder__layout">
        <div className="flow-builder__palette">
          <NodePalette nodes={mockLibraryNodes}></NodePalette>
        </div>
        <div className="flow-builder__canvas">
          <CanvasToolbar onVariablesButtonClick={onVariablesButtonClick} ref={flowDiagramRef} />
          <FlowDiagram
            ref={flowDiagramRef as any}
            defaultNodes={mockDiagramNodes}
            defaultEdges={edges}
            onRemoveEdge={handleRemoveEdge}
            onAddEdge={handleAddEdge}
            onMoveNode={handleMoveNode}
            onAddNode={handleAddNode}
            onSelectNode={() => {}}
          />
        </div>
      </div>
      <div className="demo">
        <div className="demo-div">
          <Heading level={4}>Nodes</Heading>
          {nodes.map((n, index) => (
            <div key={index}>
              {n.id.substring(0, 12)} [{round(n.position.x)}, {round(n.position.y)}]
            </div>
          ))}
        </div>
        <div className="demo-div">
          <Heading level={4}>Edges</Heading>
          {edges.map((e, index) => (
            <div key={index}>{e.id.substring(0, 12)}</div>
          ))}
        </div>
      </div>
    </div>
  );
}
