import React from 'react';
import { ReactComponent as DragSvg } from './Drag.svg';
import './NodePaletteDragHint.scss';

export default function NodePaletteDragHint() {
  return (
    <div className="node-palette-drag-hint">
      <DragSvg aria-hidden={true} />
      <div className="node-palette-drag-hint__text">Drag and drop actions into the canvas to use them</div>
    </div>
  );
}
