import { NodeType, SpecialNodeKind, LibraryNode } from '../types';
import { jiraIcon, scriptIcon, summarizeIcon, resultsIcon } from './mockIcons.js';

const mockLibraryNodes: LibraryNode[] = [
  {
    id: 'jira-id',
    displayName: 'From Jira',
    description: 'Create a table by inputting a Jira source',
    nodeType: NodeType.Source,
    icon: jiraIcon,
  },
  {
    id: 'script-id',
    displayName: 'Script',
    specialNodeKind: SpecialNodeKind.Script,
    description:
      'Find duplicate values in a single column, or in two or more columns in combination. Specify all columns to find identical records.',
    nodeType: NodeType.Transformation,
    icon: scriptIcon,
  },
  {
    id: 'summarize-id',
    displayName: 'Summarize',
    description: 'Create a new table by copying entire records or selected columns from an existing table.',
    nodeType: NodeType.Transformation,
    icon: summarizeIcon,
  },
  {
    id: 'results-id',
    displayName: 'To Results',
    description: 'Export your table to HighBond Results',
    nodeType: NodeType.Destination,
    icon: resultsIcon,
    backgroundColor: '#e5f1fe',
  },
];

export default mockLibraryNodes;
