import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import truncate from 'lodash.truncate';
import Popover from '@paprika/popover';
import RawButton from '@paprika/raw-button';
import { NodeType, SpecialNodeKind, LibraryNode } from '../types';
import { DROP_NODE_EVENT } from '../constants';
import { typeIcons } from '../NodeIcon/nodeIcons';
import NodeIcon from '../NodeIcon/NodeIcon';
import NodePaletteDragHint from './NodePaletteDragHint';
import './NodePalette.scss';

const NAME_MAX_LENGTH = 70;

interface Props {
  index: number;
  node: LibraryNode;
  shouldShowDragHint: boolean;
}

export default function NodePaletteItem({ index, node, shouldShowDragHint }: Props) {
  const { id: libraryNodeId, nodeType, specialNodeKind, icon, backgroundColor, displayName, description } = node;
  const [isHovered, setIsHovered] = useState(false);
  const [isClickPopoverOpen, setIsClickPopoverOpen] = useState(false);
  const nodeIconRef = React.useRef(null);

  useEffect(() => {
    document.addEventListener('click', handleClick);
    document.addEventListener('drag', handleDrag);

    function handleClick(event: Event) {
      if (!getThisPaletteItem().contains(event.target as Node)) {
        setIsClickPopoverOpen(false);
      }
    }
    function handleDrag() {
      closePopovers();
    }
    return () => {
      document.removeEventListener('click', handleClick);
      document.removeEventListener('drag', handleDrag);
    };
  });

  function getThisPaletteItem() {
    return document.querySelectorAll('.node-palette-item')[index];
  }

  function closePopovers() {
    setIsHovered(false);
    setIsClickPopoverOpen(false);
  }

  function handleDragStart(event) {
    closePopovers();
    event.dataTransfer.setDragImage(nodeIconRef.current, 24, 24);
    event.dataTransfer.setData(
      DROP_NODE_EVENT,
      JSON.stringify({ libraryNodeId, specialNodeKind, displayName, icon, backgroundColor, nodeType }),
    );
  }

  const TypeIcon = nodeType in typeIcons ? typeIcons[nodeType] : null;
  const truncatedDisplayName = truncate(displayName, { length: NAME_MAX_LENGTH });

  const rootClasses = classNames(
    'node-palette-item',
    { 'palette-node--script-kind': specialNodeKind === SpecialNodeKind.Script },
    { 'palette-node--source-type': nodeType === NodeType.Source },
  );

  return (
    <>
      <RawButton
        hasInsetFocusStyle
        className={rootClasses}
        data-testid="node-palette-item"
        role="listitem"
        onMouseOver={() => setIsHovered(true)}
        onMouseOut={() => setIsHovered(false)}
        onClick={() => setIsClickPopoverOpen(shouldShowDragHint)}
        draggable={true}
        onDragStart={handleDragStart}
      >
        <div className="node-palette-item__icon" ref={nodeIconRef}>
          <NodeIcon icon={icon} />
          {TypeIcon ? (
            <div className="node-palette-item__type-icon">
              <TypeIcon />
            </div>
          ) : null}
        </div>
        <div className="node-palette-item__label">{truncatedDisplayName}</div>
      </RawButton>

      {/* hover popover */}
      <Popover
        isDark
        shouldKeepFocus
        align="right"
        getPositioningElement={getThisPaletteItem}
        isOpen={isHovered && !isClickPopoverOpen}
        zIndex={2} // lets the hover popover display on top of the click popover
      >
        <Popover.Content data-testid="node-palette-item-hover-popover">
          <Popover.Card>{description}</Popover.Card>
        </Popover.Content>
        <Popover.Tip />
      </Popover>

      {/* click popover */}
      <Popover
        shouldKeepFocus
        align="right"
        maxWidth={200}
        getPositioningElement={getThisPaletteItem}
        isOpen={isClickPopoverOpen}
      >
        <Popover.Content data-testid="node-palette-item-click-popover">
          <Popover.Card className="node-palette-drag-hint__wrapper">
            <NodePaletteDragHint />
          </Popover.Card>
        </Popover.Content>
      </Popover>
    </>
  );
}
