import React, { useEffect, useState } from 'react';
import { useI18n } from '@paprika/l10n';
import Modal from '@paprika/modal';
import Button from '@paprika/button';
import { useApi } from 'providers/ApiProvider';

type Props = {
  needsUserLogin: boolean;
  onReconnect: () => void;
};

export default function WebSocketLoginModals({ needsUserLogin, onReconnect }: Props) {
  const I18n = useI18n();
  const api = useApi();

  const [isDisconnectedModalOpen, setIsDisconnectedModalOpen] = useState(false);
  const [isReconnectModalOpen, setIsReconnectModalOpen] = useState(false);

  useEffect(() => {
    if (needsUserLogin) {
      setIsDisconnectedModalOpen(true);
    }
  }, [needsUserLogin]);

  function handleLogin() {
    window.open(api.getLoginUrl());
    setIsDisconnectedModalOpen(false);
    setIsReconnectModalOpen(true);
  }

  function handleReconnect() {
    onReconnect();
    setIsReconnectModalOpen(false);
  }

  return (
    <>
      <Modal isOpen={isDisconnectedModalOpen} size="small" zIndex={7} className="websocket-login-modal">
        <Modal.Header hasCloseButton={false}>{I18n.t('websocket_disconnected_modal.heading')}</Modal.Header>
        <Modal.Content>{I18n.t('websocket_disconnected_modal.body')}</Modal.Content>
        <Modal.Footer>
          <Button kind="primary" onClick={handleLogin} tabIndex={1}>
            {I18n.t('websocket_disconnected_modal.confirm_button')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal isOpen={isReconnectModalOpen} size="small" zIndex={7} className="websocket-login-modal">
        <Modal.Header hasCloseButton={false}>{I18n.t('websocket_reconnect_modal.heading')}</Modal.Header>
        <Modal.Content>{I18n.t('websocket_reconnect_modal.body')}</Modal.Content>
        <Modal.Footer>
          <Button kind="primary" onClick={handleReconnect} tabIndex={1}>
            {I18n.t('websocket_reconnect_modal.confirm_button')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
