import React from 'react';
import Toast from 'types/Toast';
import ToastKind from 'enums/ToastKind';

type GlobalToastProviderValue = {
  currentToast: Toast | null;
  removeCurrentToast: () => void;
  setToast: (text: string, kind: ToastKind, canAutoClose: boolean) => Toast;
};

export const GlobalToastContext = React.createContext<GlobalToastProviderValue | undefined>(undefined);

export function useGlobalToast() {
  const context = React.useContext(GlobalToastContext);
  if (context === undefined) {
    throw new Error('useGlobalToast must be used within a GlobalToastProvider');
  }
  return context;
}

export default function GlobalToastProvider({ children }: { children: React.ReactNode }) {
  const [currentToast, setCurrentToast] = React.useState<Toast | null>(null);

  const value: GlobalToastProviderValue = {
    currentToast,
    removeCurrentToast: () => setCurrentToast(null),
    setToast: (text, kind, canAutoClose) => {
      const toast = { canAutoClose, text, kind };
      setCurrentToast(toast);
      return toast;
    },
  };

  return <GlobalToastContext.Provider value={value}>{children}</GlobalToastContext.Provider>;
}
