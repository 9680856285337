import React, { forwardRef } from 'react';
import Button from '@paprika/button';
import RunIcon from '@acl-services/wasabicons/lib/Play';
import StopIcon from '@acl-services/wasabicons/lib/Stop';
import VariablesIcon from '@acl-services/wasabicons/lib/Variable';
import { useI18n } from '@paprika/l10n';
import { useWebSocket } from 'providers/WebSocketProvider';
import { FlowDiagramHandle } from 'components/FlowDiagram/types';
import FlowLegend from 'components/FlowDiagram/FlowLegend/FlowLegend';
import ZoomControls from 'components/FlowDiagram/ZoomControls/ZoomControls';
import './CanvasToolbar.scss';

type Props = {
  onVariablesButtonClick: () => void;
};

function CanvasToolbar({ onVariablesButtonClick }: Props, ref) {
  const I18n = useI18n();
  const webSocket = useWebSocket();
  const iconSize = '17px';

  return (
    <div className="canvas-toolbar">
      <FlowLegend className="canvas-header__button" />
      <Button
        className="canvas-header__button"
        data-pendo-anchor="canvas-header__variables"
        icon={<VariablesIcon size={iconSize} />}
        onClick={onVariablesButtonClick}
      >
        {I18n.t('canvas.header.variables_button')}
      </Button>
      {webSocket.isExecuting ? (
        <Button
          className="canvas-header__button"
          data-pendo-anchor="canvas-header__stop"
          data-testid="canvas-header-stop-button"
          icon={<StopIcon size={iconSize} />}
          // onClick={webSocket.sendStopExecutionMessage}
        >
          {I18n.t('canvas.header.stop_button')}
        </Button>
      ) : (
        <Button
          className="canvas-header__button"
          data-pendo-anchor="canvas-header__run"
          data-testid="canvas-header-run-button"
          icon={<RunIcon size={iconSize} />}
          isDisabled={!webSocket.isConnected}
          onClick={() => {}}
          // TODO: uncomment below when users can actually use the flow diagram
          // onClick={webSocket.sendRunFlowMessage}
        >
          {I18n.t('canvas.header.run_button')}
        </Button>
      )}
      <ZoomControls ref={ref} />
    </div>
  );
}

export default forwardRef<FlowDiagramHandle, Props>(CanvasToolbar);
