import React from 'react';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/theme-tomorrow';
import ScriptCell from 'types/ScriptCell';
import './CodeEditor.scss';
import './CodeEditorStarlingTheme.scss';

type Props = {
  cell: ScriptCell;
  isReadOnly: boolean;
  onChange: (value: string, prevCell: ScriptCell) => void;
  onSelect: () => void;
  value: string;
};

function CodeEditor({ cell, isReadOnly, onChange, onSelect, value }: Props) {
  // Disable ⌘ ⇧ D, ⌘ ⇧ L, and ⌘ ⇧ / shortcut keys
  function removeShortcutConflicts(editor) {
    editor.commands.removeCommand(editor.commands.byName.duplicateSelection);
    editor.commands.removeCommand(editor.commands.byName.expandtoline);
    editor.commands.removeCommand(editor.commands.byName.toggleBlockComment);
  }

  return (
    <div className="script-node-cell__editor">
      {isReadOnly ? <div className="script-node-cell__editor-mask" /> : null}
      <AceEditor
        className="ace-starling"
        debounceChangePeriod={500}
        fontSize={14}
        maxLines={22}
        mode="python"
        onChange={(value) => onChange(value, cell)}
        readOnly={isReadOnly}
        theme="tomorrow"
        value={value}
        width="100%"
        onLoad={removeShortcutConflicts}
        onFocus={onSelect}
      />
    </div>
  );
}

export default React.memo(CodeEditor);
