export const cellContainerClassName = 'node-details__content-wrapper';
export const cellClassName = 'script-node-cell';
export const cellEditorClassName = 'ace_text-input';

export function scrollTo(position: number): void {
  if (!position) return;
  const offsetBuffer = 30;
  const $cellContainer = document.getElementsByClassName(cellContainerClassName)[0] as HTMLElement;
  $cellContainer?.scrollTo({ top: position - offsetBuffer, behavior: 'smooth' });
}
