import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import L10n from '@paprika/l10n';
import HighbondInfo from 'types/HighbondInfo';
import Locales from 'locales';

type Props = {
  children: React.ReactNode;
};

export default function L10nProvider({ children }: Props) {
  const urlParams = new URLSearchParams(window.location.search);
  const paramLocale = urlParams.get('locale');

  const { locale }: HighbondInfo = useSelector((state: RootState) => state.highbondInfo);

  return (
    <L10n locale={paramLocale || locale || 'en'} locales={Locales}>
      {children}
    </L10n>
  );
}
