import React from 'react';
import tokens from '@paprika/tokens';
import { DefaultLinkModel, DefaultLinkModelOptions } from '@projectstorm/react-diagrams';
import { BezierCurve } from '@projectstorm/geometry';
import { DiagramEngine } from '@projectstorm/react-diagrams-core';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import FlowLink from '../FlowLink/FlowLink';
import FlowLinkPath from '../FlowLink/FlowLinkPath';

interface FlowLinkModelOptions extends DefaultLinkModelOptions {
  extras: {
    linkId?: string;
    isPopoverOpen?: boolean;
  };
}

export class FlowLinkModel extends DefaultLinkModel {
  constructor(options: FlowLinkModelOptions | {} = {}) {
    const superOptions: FlowLinkModelOptions = {
      type: 'FlowLink',
      width: 4,
      color: tokens.color.blackLighten40,
      selectedColor: tokens.color.blue,
      curvyness: 50,
      ...options,
      extras: {},
    };

    super(superOptions);
  }

  getOptions() {
    return this.options as FlowLinkModelOptions;
  }

  getSVGPath(): string {
    if (this.points.length !== 2) return '';

    const curve = new BezierCurve();
    curve.setSource(this.getFirstPoint().getPosition());
    curve.setTarget(this.getLastPoint().getPosition());
    curve.setSourceControl(this.getFirstPoint().getPosition().clone());
    curve.setTargetControl(this.getLastPoint().getPosition().clone());

    if (this.sourcePort) {
      curve.getSourceControl().translate(...this.calculateControlOffset(this.getSourcePort()));
    }

    if (this.targetPort) {
      // set curve target to left edge of port
      const portCenter = this.getTargetPort().getCenter().x;
      const offset = portCenter - this.getTargetPort().width / 2;
      curve.getTarget().translate(offset - curve.getTarget().x, 0);

      curve.setTargetControl(this.getLastPoint().getPosition().clone());
      curve.getTargetControl().translate(...this.calculateControlOffset(this.getTargetPort()));
    }

    return curve.getSVGCurve();
  }
}
export class FlowLinkFactory<Link extends DefaultLinkModel = DefaultLinkModel> extends AbstractReactFactory<
  Link,
  DiagramEngine
> {
  constructor(type = 'FlowLink') {
    super(type);
  }

  generateLinkSegment(model: Link, selected: boolean, path: string) {
    return <FlowLinkPath model={model} path={path} selected={selected} />;
  }

  generateModel(): Link {
    return new FlowLinkModel() as Link;
  }

  generateReactWidget(event): JSX.Element {
    return <FlowLink link={event.model} diagramEngine={this.engine} />;
  }
}
