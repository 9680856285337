import React from 'react';
import classNames from 'classnames';

import AddCellIcon from '@acl-services/wasabicons/lib/Add';
import RunIcon from '@acl-services/wasabicons/lib/Play';
import RunNodeIcon from '@acl-services/wasabicons/lib/PlayAll';
import RevertIcon from '@acl-services/wasabicons/lib/History';
import SaveIcon from '@acl-services/wasabicons/lib/Save';
import StopIcon from '@acl-services/wasabicons/lib/Stop';
import RemoveIcon from '@acl-services/wasabicons/lib/Trashbin';
import KeyboardIcon from '@acl-services/wasabicons/lib/Keyboard';
import Popover from '@paprika/popover';

import './ScriptNodeIcon.scss';

const iconSize = '20px';

const ICONS = {
  addCell: AddCellIcon,
  keyboardShortcuts: KeyboardIcon,
  removeCell: RemoveIcon,
  revertChange: RevertIcon,
  runCell: RunIcon,
  runNode: RunNodeIcon,
  save: SaveIcon,
  stopExecute: StopIcon,
};

type Props = {
  iconType: string;
  isCentered?: boolean;
  isDisabled?: boolean;
  tooltipContent?: React.ReactNode;
};

export default function ScriptNodeIcon({
  iconType,
  isCentered = true,
  isDisabled = false,
  tooltipContent = '',
}: Props) {
  const Icon = ICONS[iconType];

  const contentClasses = classNames('script-node-icon__tooltip-content', {
    'script-node-icon__tooltip-content--centered': isCentered,
  });

  return (
    <Popover align="bottom" isDark isEager offset={8} shouldKeepFocus zIndex={9} className="script-node-icon">
      <Popover.Trigger tabIndex={isDisabled ? 0 : -1} isDisabled={isDisabled}>
        <Icon size={iconSize} />
      </Popover.Trigger>
      {tooltipContent && (
        <>
          <Popover.Content className={contentClasses}>
            <Popover.Card>{tooltipContent}</Popover.Card>
          </Popover.Content>
          <Popover.Tip />
        </>
      )}
    </Popover>
  );
}
