import React from 'react';
import Spinner from '@paprika/spinner';
import { useI18n } from '@paprika/l10n';

import './DiagramSpinner.scss';

export default function DiagramSpinner() {
  const I18n = useI18n();

  return (
    <div className="flow-diagram__spinner">
      <Spinner caption={I18n.t('canvas.loading')} />
    </div>
  );
}
