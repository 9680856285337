import React, { useState, useRef, useLayoutEffect, forwardRef } from 'react';
import classNames from 'classnames';
import Input from '@paprika/input';
import FormElement from '@paprika/form-element';
import CopyButton from './CopyButton';

type Props = {
  a11yText: string;
  error: string | null;
  dataTestId: string;
  onChange: (value: string) => void;
  inputValue?: string;
  isCopyInput?: boolean;
  isReadOnly?: boolean;
  variableId: string;
};

const RENDER_DELAY = 100;

function VariableInputWithError(
  {
    a11yText,
    error,
    dataTestId,
    onChange,
    inputValue,
    isCopyInput = false,
    isReadOnly = false,
    variableId,
    ...moreProps
  }: Props,
  ref,
) {
  const [delayedError, setDelayedError] = useState<string>('');
  const [value, setValue] = useState(inputValue || '');
  const errorTimer = useRef<any>();

  useLayoutEffect(() => {
    errorTimer.current = setTimeout(() => {
      setDelayedError(error || '');
    }, RENDER_DELAY);

    return () => {
      clearTimeout(errorTimer.current);
    };
  }, [error]);

  const errorId = `${dataTestId}-error-${variableId}`;
  const copyInputContainerClasses = classNames('variable__input-container', {
    'variable__input-container--copy-input': isCopyInput,
  });

  return (
    <>
      <div className={copyInputContainerClasses}>
        <Input
          ref={ref}
          a11yText={a11yText}
          hasError={delayedError !== ''}
          defaultValue={inputValue}
          key={variableId}
          data-testid={dataTestId}
          isReadOnly={isReadOnly}
          onBlur={(e) => onChange(e.target.value)}
          onChange={(e) => setValue(e.target.value)}
          aria-describedby={errorId}
          {...moreProps}
        />
        {isCopyInput ? <CopyButton value={value} buttonKind="flat" /> : null}
      </div>
      {delayedError && (
        <FormElement.Error className="variable__input-error" data-testid="variable-input-error" id={errorId}>
          {delayedError}
        </FormElement.Error>
      )}
    </>
  );
}

export default forwardRef(VariableInputWithError);
