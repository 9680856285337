import * as React from 'react';
import { DiagramEngine } from '@projectstorm/react-diagrams';
import RemoveIcon from '@acl-services/wasabicons/lib/Trashbin';
import Button from '@paprika/button';
import Heading from '@paprika/heading';
import Popover from '@paprika/popover';
import { useI18n } from '@paprika/l10n';
import { FlowLabelModel } from '../models/labelModel';
import { focusLink, focusNode } from '../flowDiagramHelpers';

import './FlowLabel.scss';

interface Props {
  labelModel: FlowLabelModel;
  engine: DiagramEngine;
}

export default function FlowLabel(props: Props) {
  const { engine, labelModel } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  const I18n = useI18n();

  const handleConfirm = () => {
    labelModel.linkModel.remove();
    engine.repaintCanvas();
    const nodeId = labelModel.linkModel.getSourcePort().getParent().getOptions().extras.nodeId;
    focusNode(nodeId);
  };

  const handleClose = () => {
    labelModel.linkModel.getOptions().extras.isPopoverOpen = false;
    labelModel.linkModel.getLabels().length = 0;
    setIsOpen(false);
    engine.repaintCanvas();
    const linkId = labelModel.linkModel.getOptions().extras.linkId;
    focusLink(linkId);
  };

  const handleOpenPopover = () => {
    labelModel.linkModel.getOptions().extras.isPopoverOpen = true;
    setIsOpen((prevOpen) => !prevOpen);
  };

  return (
    <div className="label-widget">
      <Popover align="top" edge="left" isOpen={isOpen} offset={4} onClose={handleClose}>
        <Button.Icon
          kind="destructive"
          onClick={handleOpenPopover}
          data-testid="remove-link__popover-trigger"
          a11yText={I18n.t('delete_confirmation.title')}
        >
          <RemoveIcon />
        </Button.Icon>
        <Popover.Content className="label-widget__popcontent">
          <Popover.Card>
            <Heading displayLevel={5} level={2}>
              {I18n.t('delete_confirmation.title')}
            </Heading>
            <p>{I18n.t('delete_confirmation.description')}</p>
            <Button canPropagate={false} kind="destructive" onClick={handleConfirm} data-testid="remove-link__button">
              {I18n.t('common.delete')}
            </Button>
            <Button canPropagate={false} kind="minor" onClick={handleClose}>
              {I18n.t('common.cancel')}
            </Button>
          </Popover.Card>
        </Popover.Content>
      </Popover>
    </div>
  );
}
