import * as React from 'react';
import classNames from 'classnames';
import { FlowLinkFactory, FlowLinkModel } from '../models/linkModel';
import { INPUT_LINK_COLOUR, OUTPUT_LINK_COLOUR } from '../constants';

interface Props {
  path: string;
  link: FlowLinkModel;
  selected: boolean;
  forwardRef: React.RefObject<SVGPathElement>;
  factory: FlowLinkFactory;
  onKeyDown: (boolean) => void;
  onFocus: () => void;
  onBlur: () => void;
}

export const EdgeContext = React.createContext({});

export default function FlowLinkSegment(props: Props) {
  const { onKeyDown, onBlur, onFocus, link, path, selected, forwardRef, factory } = props;
  const edges = React.useContext(EdgeContext);
  const color = link.getOptions().color;
  const isSelected = selected || link.isSelected();
  const bottomClasses = classNames(
    'flow-link__path',
    { 'flow-link__path--output': color === OUTPUT_LINK_COLOUR },
    { 'flow-link__path--input': color === INPUT_LINK_COLOUR },
  );
  const bottomBorderClasses = 'flow-link__path flow-link__path--border';
  const topClasses = classNames(bottomClasses, 'flow-link__path--overlay', { 'flow-link__path--selected': isSelected });
  const linkId = link.getOptions().extras.linkId!;

  const handleBlur = () => {
    onBlur();
  };

  const handleFocus = () => {
    onFocus();
  };

  const handleKeyDown = (e) => {
    if (['Backspace', 'Delete'].includes(e.key)) {
      e.preventDefault();
      onKeyDown(true);
    }

    if (['Enter', ' '].includes(e.key)) {
      e.preventDefault();
      onKeyDown(!isSelected);
    }
  };

  const Bottom = React.cloneElement(factory.generateLinkSegment(link, isSelected, path), {
    ref: forwardRef,
    className: bottomClasses,
  });

  const BottomBorder = React.cloneElement(factory.generateLinkSegment(link, isSelected, path), {
    ref: forwardRef,
    className: bottomBorderClasses,
  });

  const Top = React.cloneElement(Bottom, {
    ref: null,
    className: topClasses,
    tabIndex: 0,
    onKeyDown: handleKeyDown,
    onFocus: handleFocus,
    onBlur: handleBlur,
    role: 'button',
    'aria-label': edges[linkId] ? edges[linkId].ariaLabel : null,
    'aria-pressed': isSelected || false,
  });

  return (
    <g className="flow-link__segment">
      {isSelected && BottomBorder}
      {Bottom}
      {Top}
    </g>
  );
}
