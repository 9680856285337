import React from 'react';
import { useSelector } from 'react-redux';
import { useI18n } from '@paprika/l10n';
import { RootState } from 'rootReducer';
import './AppVersion.scss';

interface AppVersionProps {
  appVersionText?: string;
}

export default function AppVersion({ appVersionText }: AppVersionProps) {
  const I18n = useI18n();
  const { robotVersion } = useSelector((state: RootState) => state.robotVersion);

  return (
    <div className="app-version__icon">
      {appVersionText || I18n.t('canvas.header.app_version', { appVersion: robotVersion.version })}
    </div>
  );
}
