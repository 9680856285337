import React from 'react';

// Initial amount to increment the progress value after each interval
const BASE_INCREMENT = 0.35;

// Initial threshold that progress value must exceed before decay is applied
const BASE_THRESHOLD = 10;

// Delay before applying increment to progress value
const INTERVAL = 100;

// Multiplier to apply to the increments for both progress value and threshold
const DECAY = 0.9;

/*
 * The effect of progressively diminishing the increment and thresholdIncrement
 * is a progress value that advances more and more slowly over time and should
 * never be able to reach 100%.
 */

export default function useFakeLoad(isDisabled = false) {
  const [value, setValue] = React.useState(0);
  const increment = React.useRef(BASE_INCREMENT);
  const threshold = React.useRef(BASE_THRESHOLD);
  const thresholdIncrement = React.useRef(BASE_THRESHOLD);

  React.useEffect(() => {
    if (isDisabled) return;

    if (value >= threshold.current) {
      thresholdIncrement.current *= DECAY;
      increment.current *= DECAY;
      threshold.current += thresholdIncrement.current;
    }

    if (value < 100) {
      setTimeout(() => {
        setValue((value) => value + increment.current);
      }, INTERVAL);
    } else {
      setValue(100);
    }
  }, [value, isDisabled]);

  return value;
}
