import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import RobotApiService from 'services/RobotApiService';
import RobotVersion from 'types/RobotVersion';

export const fetchRobotVersion = createAsyncThunk(
  'users/fetchRobotVersion',
  async ({ orgId, robotId, appId }: { orgId: string; robotId: string; appId: string }, { rejectWithValue }) => {
    const api = new RobotApiService();
    try {
      return await api.getApp(orgId, robotId, appId);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

type RobotVersionState = {
  robotVersion: RobotVersion;
  error?: number;
};

const robotVersionSlice = createSlice({
  name: 'robotVersion',
  initialState: { robotVersion: {}, error: undefined } as RobotVersionState,
  reducers: {
    updateVersion(state, action: PayloadAction<Partial<RobotVersion>>) {
      return {
        ...state,
        robotVersion: {
          ...state.robotVersion,
          ...action.payload,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRobotVersion.fulfilled, (state, { payload }) => {
      state.robotVersion = payload;
      state.error = undefined;
    });
    builder.addCase(fetchRobotVersion.rejected, (state, { payload: error }) => {
      state.error = error as number;
    });
  },
});

export const { updateVersion } = robotVersionSlice.actions;

export default robotVersionSlice.reducer;
