enum VariableProperty {
  Name = 'name',
  Value = 'value',
  Type = 'type',
  IsReadOnly = 'isReadOnly',
  IsTaskInput = 'isTaskInput',
  TaskInputLabel = 'taskInputLabel',
  IsTaskInputValueOptional = 'isTaskInputValueOptional',
  TaskInputDescription = 'taskInputDescription',
}

export default VariableProperty;
