import { JSEncrypt } from 'jsencrypt';
import CryptoJS from 'crypto-js';

export default class SecretEncrypt {
  private publicKey: string | undefined = undefined;
  private privateKey: string | undefined = undefined;

  setPublicKey(publicKey: string) {
    this.publicKey = publicKey;
    return this;
  }

  setPrivateKey(privateKey: string) {
    this.privateKey = privateKey;
    return this;
  }

  encrypt(value: string): string | undefined {
    if (!this.publicKey) return;

    const rsaEncryptedValue = this.rsaEncrypt(value);
    return rsaEncryptedValue ? rsaEncryptedValue : this.aesEncrypt(value);
  }

  // decrypt method is used for test only right now
  decrypt(value: string): string {
    return this.isLongEncryptedSecret(value) ? this.aesDecrypt(value) : this.rsaDecrypt(value);
  }

  private isLongEncryptedSecret(value: string): boolean {
    return value.length > 344;
  }

  private rsaEncrypt(value: string): string {
    const jsEncrypt = new JSEncrypt();
    jsEncrypt.setPublicKey(this.publicKey);

    return jsEncrypt.encrypt(value) || '';
  }

  private rsaDecrypt(value: string): string {
    const jsEncrypt = new JSEncrypt();
    jsEncrypt.setPrivateKey(this.privateKey);

    return jsEncrypt.decrypt(value) || '';
  }

  private aesEncrypt(value: string): string | undefined {
    const key = CryptoJS.lib.WordArray.random(32);
    const iv = CryptoJS.lib.WordArray.random(16);
    const crc = CryptoJS.lib.WordArray.random(2);
    const mode = CryptoJS.mode.CBC;

    const encrypted = CryptoJS.AES.encrypt(crc.toString() + value, key, { iv, mode });
    const ciphertext: string = encrypted.toString();
    const keyiv = crc.toString() + iv.toString() + key.toString();
    const encryptedKeyiv: string = this.rsaEncrypt(keyiv);

    return encryptedKeyiv ? encryptedKeyiv + ciphertext : undefined;
  }

  private aesDecrypt(value: string): string {
    const encryptedPassphrase: string = value.substring(0, 344);
    const encryptedSecret: string = value.substring(344, value.length);
    const keyiv: string = this.rsaDecrypt(encryptedPassphrase);
    const crc: string = keyiv.substring(0, 4);
    const ivHex: string = keyiv.substring(4, 36);
    const keyHex: string = keyiv.substring(36, 100);
    const mode = CryptoJS.mode.CBC;

    const iv = CryptoJS.enc.Hex.parse(ivHex);
    const key = CryptoJS.enc.Hex.parse(keyHex);
    const bytes = CryptoJS.AES.decrypt(encryptedSecret, key, { iv, mode });
    const originalTextIncludePassphrase = bytes.toString(CryptoJS.enc.Latin1);
    if (!originalTextIncludePassphrase.startsWith(crc)) {
      throw new Error('passphrase not match');
    }

    const [, decryptedSecret] = originalTextIncludePassphrase.split(crc);
    return decryptedSecret;
  }
}
