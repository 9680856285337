import { NodeModel } from '@projectstorm/react-diagrams';
import last from 'lodash.last';
import { Node, DiagramNode } from './types';
import { FlowNodeModel } from './models/nodeModel';
import { FlowPortModel } from './models/portModel';
import { FlowLinkModel } from './models/linkModel';
import { GRID_SIZE, ZOOM_PERCENTAGES } from './constants';

export function normalizeNodes(nodeModels: NodeModel[], diagramNodes: DiagramNode[]) {
  const normalizedNodes = {} as { [nodeId: string]: Node };
  for (const nodeModel of nodeModels) {
    const nodeId = nodeModel.getOptions().extras.nodeId;
    if (nodeId) {
      normalizedNodes[nodeId] = {
        model: nodeModel as FlowNodeModel,
      };
    }
  }
  for (const node of diagramNodes) {
    const { id, name, state } = node;
    normalizedNodes[id] = { ...normalizedNodes[id], name, state };
  }

  return normalizedNodes;
}

export function findNode(engineModel, nodeId) {
  return engineModel?.getNodes().find((node) => node.getOptions().extras.nodeId === nodeId);
}

export function findLink(engineModel, edgeId) {
  return engineModel?.getLinks().find((link) => link.getOptions().extras.linkId === edgeId);
}

export function focusLink(edgeId) {
  const edge = document.querySelector(
    `[data-canvas-link-id="${edgeId}"] .flow-link__segment .flow-link__path--overlay`,
  ) as HTMLElement;

  if (edge) {
    edge.focus();
  }
}

export function focusNode(nodeId) {
  const node = document.querySelector(`[data-canvas-node-id="${nodeId}"]`) as HTMLElement;
  if (node) {
    node.focus();
  }
}

export function createNode(node: DiagramNode) {
  const nodeModel = new FlowNodeModel(node);
  nodeModel.setPosition(node.position.x, node.position.y);
  return nodeModel;
}

export function createLink(sourcePort: FlowPortModel, targetPort: FlowPortModel, linkId?: string): FlowLinkModel {
  const newLink = sourcePort.link(targetPort) as FlowLinkModel;
  newLink.getOptions().extras.linkId = linkId || newLink.getOptions().id;
  sourcePort.reportPosition();
  targetPort.reportPosition();
  return newLink;
}

export function getNextZoomInPercentage(currentZoom: number) {
  const currentIndex = ZOOM_PERCENTAGES.indexOf(currentZoom);
  // zoomToFit can result in a zoom percentage that isn't in ZOOM_PERCENTAGES
  if (currentIndex === -1) {
    const nextPercentage = ZOOM_PERCENTAGES.filter((zoom) => currentZoom - zoom < 0)[0];
    const nextPercentageIndex = ZOOM_PERCENTAGES.indexOf(nextPercentage);
    const newIndex =
      nextPercentageIndex > -1 && nextPercentageIndex < ZOOM_PERCENTAGES.length
        ? nextPercentageIndex
        : ZOOM_PERCENTAGES.length - 1;
    return ZOOM_PERCENTAGES[newIndex];
  }
  if (currentIndex + 1 < ZOOM_PERCENTAGES.length) {
    return ZOOM_PERCENTAGES[currentIndex + 1];
  }
  return last(ZOOM_PERCENTAGES);
}

export function getNextZoomOutPercentage(currentZoom: number) {
  const currentIndex = ZOOM_PERCENTAGES.indexOf(currentZoom);
  if (currentIndex === -1) {
    const previousPercentage = last(ZOOM_PERCENTAGES.filter((zoom) => currentZoom - zoom > 0));
    const previousPercentageIndex = ZOOM_PERCENTAGES.indexOf(previousPercentage);
    const newIndex =
      previousPercentageIndex > -1 && previousPercentageIndex < ZOOM_PERCENTAGES.length ? previousPercentageIndex : 0;
    return ZOOM_PERCENTAGES[newIndex];
  } else if (currentIndex - 1 > 0) {
    return ZOOM_PERCENTAGES[currentIndex - 1];
  }
  return ZOOM_PERCENTAGES[0];
}

export function roundNumberForGrid(number) {
  return Math.round(number / GRID_SIZE) * GRID_SIZE;
}
