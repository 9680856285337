export const jiraIcon = `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M37.0392 19.3073L21.2613 3.52939L19.7319 2L7.85494 13.877L2.42463 19.3073C2.15273 19.5795 2 19.9486 2 20.3333C2 20.7181 2.15273 21.0871 2.42463 21.3594L13.2756 32.2103L19.7319 38.6667L31.6089 26.7897L31.7928 26.6058L37.0392 21.3594C37.3111 21.0871 37.4638 20.7181 37.4638 20.3333C37.4638 19.9486 37.3111 19.5795 37.0392 19.3073ZM19.7319 25.754L14.3113 20.3333L19.7319 14.9127L25.1525 20.3333L19.7319 25.754Z" fill="#2684FF" />
<path d="M19.7319 14.9126C18.027 13.2075 17.066 10.8972 17.0588 8.48601C17.0515 6.07485 17.9986 3.75875 19.6932 2.04346L7.83069 13.9011L14.287 20.3574L19.7319 14.9126Z" fill="url(#paint0_linear)" />
<path d="M25.1671 20.3186L19.7319 25.7537C20.5801 26.6014 21.253 27.6079 21.712 28.7157C22.1711 29.8235 22.4074 31.0109 22.4074 32.2101C22.4074 33.4093 22.1711 34.5967 21.712 35.7045C21.253 36.8123 20.5801 37.8188 19.7319 38.6665L31.6234 26.775L25.1671 20.3186Z" fill="url(#paint1_linear)" />
<defs>
<linearGradient id="paint0_linear" x1="18.7639" y1="9.42906" x2="11.1944" y2="16.9986" gradientUnits="userSpaceOnUse">
<stop offset="0.18" stop-color="#0052CC" />
<stop offset="1" stop-color="#2684FF" />
</linearGradient>
<linearGradient id="paint1_linear" x1="480.884" y1="1126.33" x2="742.32" y2="956.887" gradientUnits="userSpaceOnUse">
<stop offset="0.18" stop-color="#0052CC" />
<stop offset="1" stop-color="#2684FF" />
</linearGradient>
</defs>
</svg>`;

export const scriptIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="480" height="480" viewBox="0 0 480 480">
<path fill="#f0a117" fill-rule="evenodd" d="M400,118.01 L360,118.01 L360,98.01 C360,86.99 368.98,78.01 380,78.01 C391.02,78.01 400,86.99 400,98.01 L400,118.01 Z M320,358.01 C320,380.07 302.06,398.01 280,398.01 C257.94,398.01 240,380.07 240,358.01 C240,335.95 257.94,318.01 280,318.01 C291.04,318.01 300,309.05 300,298.01 C300,286.97 291.04,278.01 280,278.01 L160,278.01 L160,98.01 C160,86.99 168.98,78.01 180,78.01 L323.68,78.01 C321.44,84.29 320,90.97 320,98.01 L320,358.01 Z M210.74,398.01 L120,398.01 C97.94,398.01 80,380.07 80,358.01 C80,335.95 97.94,318.01 120,318.01 L210.74,318.01 C203.9,329.79 200,343.45 200,358.01 C200,372.57 203.9,386.23 210.74,398.01 L210.74,398.01 Z M380,38.01 L180,38.01 C146.92,38.01 120,64.93 120,98.01 L120,278.01 C75.88,278.01 40,313.89 40,358.01 C40,402.13 75.88,438.01 120,438.01 L280,438.01 C324.12,438.01 360,402.13 360,358.01 L360,158.01 L420,158.01 C431.04,158.01 440,149.05 440,138.01 L440,98.01 C440,64.93 413.08,38.01 380,38.01 L380,38.01 Z" />
</svg>`;

export const summarizeIcon = `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill="currentColor" fill-rule="evenodd" clip-rule="evenodd" d="M4.99996 15V3.33333H11.6666H15V6.66667L15.001 11.6667H15C13.1616 11.6667 11.6666 13.1617 11.6666 15L4.99996 15ZM11.6666 18.3333H4.99996C3.16163 18.3333 1.66663 16.8383 1.66663 15V3.33333C1.66663 1.49667 3.16163 0 4.99996 0H15C16.8383 0 18.3333 1.49667 18.3333 3.33333V11.6667L25 11.6667C26.8383 11.6667 28.3333 13.1617 28.3333 15L28.3333 21.6667H35C36.8383 21.6667 38.3333 23.1633 38.3333 25V36.6667C38.3333 38.505 36.8383 40 35 40H25C23.1616 40 21.6666 38.505 21.6666 36.6667V30L15 30C13.1616 30 11.6666 28.5033 11.6666 26.6667L11.6666 18.3333ZM21.6666 26.6667H18.3333H15V23.3333L14.999 18.3333H15L15 15H15.0016L18.3333 15L25 15V21.6667C23.1616 21.6667 21.6666 23.1633 21.6666 25V26.6667ZM25 26.6667L25 30V36.6667H35.0016L35 28.3333V25H31.6666H28.3333H25L25 26.6667Z" />
</svg>`;

export const resultsIcon = `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill="#0063c5" fill-rule="evenodd" clip-rule="evenodd" d="M12.2222 18.0536H16.1111V33.6092H12.2222V18.0536ZM23.8889 33.6092H20V25.8314H23.8889V33.6092ZM27.7778 10.2778H31.6667V33.6092H27.7778V10.2778ZM35.5556 33.6111V8.33333C35.5556 7.26 34.6844 6.38889 33.6111 6.38889H25.8333C24.76 6.38889 23.8889 7.26 23.8889 8.33333V21.9425H20V16.1092C20 15.0358 19.1289 14.1647 18.0556 14.1647H10.2778C9.20444 14.1647 8.33333 15.0358 8.33333 16.1092V33.6111H6.38889V2.5H2.5V35.5556C2.5 36.6289 3.37111 37.5 4.44444 37.5H37.5V33.6111H35.5556Z" />
</svg>`;
